<template>
  <div class="content">
    <add-sampler-modal
      v-if="showAddSamplerModal"
      @closeModal="closeAddDeleteSamplerModal"
    />
    <delete-sampler-modal
      v-if="showDeleteSamplerModal"
      :samplerId="samplerIdSelected"
      @closeModal="closeAddDeleteSamplerModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Amostradores
        </h5>
        <div v-if="getModuleUserByCode('2.02.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Vincular Amostrador"
            @click="addSampler()"
          >
            Vincular Amostrador
          </button>
        </div>
      </div>
      <list-samplers :samplers="samplers" @removeSampler="removeSampler" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import AddSamplerModal from '@/components/precision/samplers/AddSamplerModal.vue'
import ListSamplers from '@/components/precision/samplers/ListSamplers.vue'
import DeleteSamplerModal from '@/components/precision/samplers/DeleteSamplerModal.vue'

export default {
  name: 'PrecisionSamplers',

  components: {
    LoadingData,
    AddSamplerModal,
    ListSamplers,
    DeleteSamplerModal,
  },

  data() {
    return {
      loadData: true,
      showAddSamplerModal: false,
      showDeleteSamplerModal: false,
      samplers: [],
      samplerIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getSamplers() {
      try {
        this.samplers = await this.$store.dispatch('getSamplers')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    addSampler() {
      this.showAddSamplerModal = true
    },

    removeSampler(samplerId) {
      this.samplerIdSelected = samplerId
      this.showDeleteSamplerModal = true
    },

    closeAddDeleteSamplerModal(loadListSampler) {
      if (loadListSampler) {
        this.loadData = true
        this.getSamplers()
      }
      this.showAddSamplerModal = false
      this.showDeleteSamplerModal = false
    },
  },

  mounted() {
    this.getSamplers()
  },
}
</script>
