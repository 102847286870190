<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/reports" title="Voltar">
            Relatórios
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Visão Geral
        </h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="search">
          <div class="form-row">
            <div class="form-group col-12 col-sm-3">
              <label>Safra: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.filters.harvestId.$error }"
                v-model="$v.filters.harvestId.$model"
              >
                <option value="">Selecione a Safra</option>
                <option
                  v-for="harvest in harvests"
                  :key="harvest.id"
                  :value="harvest.id"
                  :title="harvest.name"
                >
                  {{ harvest.name }}
                </option>
              </select>
              <span
                v-if="!$v.filters.harvestId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-3">
              <label>Estado: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.filters.stateId.$error }"
                v-model="$v.filters.stateId.$model"
              >
                <option value="">Selecione um Estado</option>
                <option
                  v-for="state in states"
                  :key="state.id"
                  :value="state.id"
                  :title="`${state.uf} - ${state.name}`"
                >
                  {{ state.name }}
                </option>
              </select>
              <span
                v-if="!$v.filters.stateId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-3">
              <label>Data Inicial: <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control"
                :class="{ 'is-invalid': $v.filters.startDate.$error }"
                v-model="$v.filters.startDate.$model"
              />
              <span
                v-if="!$v.filters.startDate.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-3">
              <label>Data final: <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control"
                :class="{ 'is-invalid': $v.filters.endDate.$error }"
                v-model="$v.filters.endDate.$model"
              />
              <span
                v-if="!$v.filters.endDate.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <div class="text-right">
            <button
              type="submit"
              class="btn btn-primary"
              title="Buscar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Buscar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <report-overview v-if="dataReport.report" :dataReport="dataReport" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { numberToCurrencyBr } from '@/utils/strings'
import { dateSqlToDateBR } from '@/utils/date'
import ReportOverview from '@/components/precision/report/ReportOverview.vue'

export default {
  name: 'PrecisionReportOverview',

  components: {
    ReportOverview,
  },

  data() {
    return {
      harvests: [],
      states: [],
      filters: {
        harvestId: '',
        stateId: '',
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: '',
      },
      dataReport: {
        report: null,
      },
      disableButton: false,
    }
  },

  methods: {
    async getHarvests() {
      try {
        this.harvests = await this.$store.dispatch('getHarvests')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async search() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'getReportOverview',
            this.filters,
          )
          this.dataReport = this.formatData(response)
          this.disableButton = false
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    formatData(data) {
      return {
        report: data.report.map((element) => {
          return {
            ...element,
            hectareToCurrencyBr: numberToCurrencyBr(element.hectare),
            hectare: Number(element.hectare),
            colectedToCurrencyBr: numberToCurrencyBr(element.colected),
            colected: Number(element.colected),
            dateToCurrencyBr: dateSqlToDateBR(element.date),
          }
        }),
        totals: {
          area: numberToCurrencyBr(data.totals.area),
          coleted: numberToCurrencyBr(data.totals.coleted),
        },
        sortColumn: '',
        sortType: '',
      }
    },
  },

  validations: {
    filters: {
      harvestId: {
        required,
      },
      stateId: {
        required,
      },
      startDate: {
        required,
      },
      endDate: {
        required,
      },
    },
  },

  mounted() {
    this.getHarvests()
    this.getStates()
  },
}
</script>
