<template>
  <div class="content">
    <create-edit-harvest-modal
      v-if="showCreateEditHarvestModal"
      :harvestId="harvestIdSelected"
      @closeModal="closeCreateEditHarvestModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Safras
        </h5>
        <div v-if="getModuleUserByCode('2.01.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Safra"
            @click="createEditHarvest()"
          >
            Nova Safra
          </button>
        </div>
      </div>
      <list-harvests :harvests="harvests" @editHarvest="createEditHarvest" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditHarvestModal from '@/components/precision/harvests/CreateEditHarvestModal.vue'
import ListHarvests from '@/components/precision/harvests/ListHarvests.vue'

export default {
  name: 'PrecisionHarvests',

  components: {
    LoadingData,
    CreateEditHarvestModal,
    ListHarvests,
  },

  data() {
    return {
      loadData: true,
      showCreateEditHarvestModal: false,
      harvests: [],
      harvestIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getHarvests() {
      try {
        this.harvests = await this.$store.dispatch('getHarvests')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditHarvest(harvestId) {
      this.harvestIdSelected = harvestId || null
      this.showCreateEditHarvestModal = true
    },

    closeCreateEditHarvestModal(loadListHarvests) {
      if (loadListHarvests) {
        this.loadData = true
        this.getHarvests()
      }
      this.showCreateEditHarvestModal = false
    },
  },

  mounted() {
    this.getHarvests()
  },
}
</script>
