<template>
  <div class="content">
    <create-edit-maintenance-plan-modal
      v-if="showCreateEditMaintenancePlanModal"
      :maintenancePlanId="maintenancePlanIdSelected"
      @closeModal="closeCreateEditMaintenancePlanModal"
    />
    <details-maintenance-plan-modal
      v-if="showDetailsMaintenancePlanModal"
      :maintenancePlanId="maintenancePlanIdSelected"
      @closeModal="showDetailsMaintenancePlanModal = false"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/equipments" title="Voltar">
            Equipamentos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Planos de Manutenção
        </h5>
        <div v-if="getModuleUserByCode('2.06.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Plano de Manutenção"
            @click="createEditMaintenancePlan()"
          >
            Novo Plano de Manutenção
          </button>
        </div>
      </div>
      <list-maintenance-plans
        :maintenancePlans="maintenancePlans"
        @editMaintenancePlan="createEditMaintenancePlan"
        @detailsMaintenancePlan="detailsMaintenancePlan"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditMaintenancePlanModal from '@/components/precision/equipments/maintenancePlans/CreateEditMaintenancePlanModal.vue'
import DetailsMaintenancePlanModal from '@/components/precision/equipments/maintenancePlans/DetailsMaintenancePlanModal.vue'
import ListMaintenancePlans from '@/components/precision/equipments/maintenancePlans/ListMaintenancePlans.vue'

export default {
  name: 'PrecisionMaintenancePlans',

  components: {
    LoadingData,
    CreateEditMaintenancePlanModal,
    DetailsMaintenancePlanModal,
    ListMaintenancePlans,
  },

  data() {
    return {
      loadData: true,
      showCreateEditMaintenancePlanModal: false,
      showDetailsMaintenancePlanModal: false,
      maintenancePlans: [],
      maintenancePlanIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getMaintenancePlans() {
      try {
        this.maintenancePlans = await this.$store.dispatch(
          'getMaintenancePlans',
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditMaintenancePlan(maintenancePlanId) {
      this.maintenancePlanIdSelected = maintenancePlanId || null
      this.showCreateEditMaintenancePlanModal = true
    },

    closeCreateEditMaintenancePlanModal(loadListMaintenancePlans) {
      if (loadListMaintenancePlans) {
        this.loadData = true
        this.getMaintenancePlans()
      }
      this.showCreateEditMaintenancePlanModal = false
    },

    detailsMaintenancePlan(maintenancePlanId) {
      this.maintenancePlanIdSelected = maintenancePlanId
      this.showDetailsMaintenancePlanModal = true
    },
  },

  mounted() {
    this.getMaintenancePlans()
  },
}
</script>
