<template>
  <div>
    <alert-appointment-equipment
      v-if="equipments.length > 0"
      :equipments="equipments"
    />
  </div>
</template>

<script>
import AlertAppointmentEquipment from '@/components/precision/home/AlertAppointmentEquipment.vue'

export default {
  name: 'Alerts',

  components: {
    AlertAppointmentEquipment,
  },

  data() {
    return {
      equipments: [],
    }
  },

  methods: {
    async getEquipmentsWithoutAppointment() {
      try {
        this.equipments = await this.$store.dispatch(
          'getEquipmentsWithoutAppointment',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getEquipmentsWithoutAppointment()
  },
}
</script>
