<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <p>
          <strong>Número do Quadriciclo: </strong>
          {{ equipment.name }}
        </p>
      </div>
      <div class="col-md-4 col-sm-6">
        <p><strong>Chassi: </strong> {{ equipment.chassi }}</p>
      </div>
      <div class="col-md-4 col-sm-6">
        <p>
          <strong>Ano / Cor: </strong> {{ equipment.year }} /
          {{ equipment.color }}
        </p>
      </div>
      <div class="col-md-4 col-sm-6">
        <p>
          <strong>Status: </strong>
          <span v-html="statusTranslate(equipment.status)" />
        </p>
      </div>
      <div class="col-md-4 col-sm-6">
        <p>
          <strong>Criado por:</strong>
          {{ createdUpdatedUser(equipment.createdBy, equipment.createdAt) }}
        </p>
      </div>
      <div class="col-md-4 col-sm-6">
        <p>
          <strong>Atualizado por:</strong>
          {{ createdUpdatedUser(equipment.updatedBy, equipment.updatedAt) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { createdUpdatedUser, statusTranslate } from '@/utils/strings'

export default {
  name: 'EquipmentDetails',

  props: {
    equipment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      createdUpdatedUser,
      statusTranslate,
    }
  },
}
</script>
