var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataReport.report.length > 0)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('report-buttons',{attrs:{"nameExcel":'Relatorio_Visao_Geral',"worksheetExcel":_vm.title,"fieldsExcel":_vm.fieldsExcel,"dataExcel":_vm.dataReport.report,"divElementPrint":'printReport'}}),_c('div',{attrs:{"id":"printReport"}},[_c('report-header',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered dataTable table-report"},[_c('thead',[_c('tr',[_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'laboratory' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'laboratory' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('laboratory')}}},[_vm._v(" Laboratório ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'client' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'client' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('client')}}},[_vm._v(" Cliente ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'farm' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'farm' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('farm')}}},[_vm._v(" Fazenda ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'area' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'area' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('area')}}},[_vm._v(" Talhão ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'hectare' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'hectare' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('hectare')}}},[_vm._v(" Área ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'colected' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'colected' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('colected')}}},[_vm._v(" Coletado ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'samples' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'samples' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('samples')}}},[_vm._v(" Amostrador ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'date' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'date' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('date')}}},[_vm._v(" Coleta ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'status' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'status' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('status')}}},[_vm._v(" Status ")]),_c('th',[_vm._v("Observação")])])]),_c('tbody',[_vm._l((_vm.dataReport.report),function(report,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(report.laboratory ? report.laboratory : '-'))]),_c('td',[_vm._v(_vm._s(report.client))]),_c('td',[_vm._v(_vm._s(report.farm))]),_c('td',[_vm._v(_vm._s(report.area))]),_c('td',[_vm._v(_vm._s(report.hectareToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.colectedToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.samples))]),_c('td',[_vm._v(_vm._s(report.dateToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.status))]),_c('td',[_vm._v(_vm._s(report.observation))])])}),_c('tr',[_vm._m(0),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v(_vm._s(_vm.dataReport.totals.area))]),_c('td',[_vm._v(_vm._s(_vm.dataReport.totals.coleted))]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")])])],2)])])],1)],1)])]):_c('report-not-found')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Total")])])}]

export { render, staticRenderFns }