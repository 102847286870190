<template>
  <div class="content d-flex flex-column">
    <header-page />
    <alerts v-if="userIsSampler" />
    <functionalities />
    <graphics v-if="getModuleUserByCode('2')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderPage from '@/components/precision/home/HeaderPage.vue'
import Alerts from '@/components/precision/home/Alerts.vue'
import Functionalities from '@/components/precision/home/Functionalities.vue'
import Graphics from '@/components/precision/home/Graphics.vue'

export default {
  name: 'PrecisionHome',

  components: {
    HeaderPage,
    Alerts,
    Functionalities,
    Graphics,
  },

  computed: {
    ...mapGetters(['getModuleUserByCode', 'userIsSampler']),
  },
}
</script>
