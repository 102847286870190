<template>
  <div class="text-right mb-3">
    <button
      class="btn btn-success mr-2"
      title="Exportar para Excel"
      @click="excel"
    >
      <i class="far fa-file-excel mr-2" />Excel
    </button>
    <button
      class="btn btn-warning"
      title="Abre a caixa de impressão para gerar o PDF"
      @click="print(divElementPrint)"
    >
      <i class="fas fa-file-pdf mr-2" />PDF (Imprimir)
    </button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx'

export default {
  name: 'ReportButtons',

  props: {
    nameExcel: {
      type: String,
      required: true,
    },
    worksheetExcel: {
      type: String,
      required: true,
    },
    fieldsExcel: {
      type: Object,
      required: true,
    },
    dataExcel: {
      type: Array,
      required: true,
    },
    divElementPrint: {
      type: String,
      required: true,
    },
  },

  methods: {
    excel() {
      const settings = {
        fileName: this.nameExcel,
      }

      const template = [
        {
          sheet: this.worksheetExcel,
          columns: [],
          content: [],
        },
      ]

      const fieldsName = Object.keys(this.fieldsExcel)
      const fieldsValue = Object.values(this.fieldsExcel)

      fieldsName.forEach((field, index) => {
        template[0].columns.push({
          label: field,
          value: fieldsValue[index],
        })
      })

      template[0].content = this.dataExcel

      xlsx(template, settings)
    },

    async print(element) {
      await this.$htmlToPaper(element)
    },
  },
}
</script>
