<template>
  <div class="content">
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/equipments" title="Voltar">
            Equipamentos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/equipments/list" title="Voltar">
            Listagem
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Detalhes do Equipamento
        </h5>
      </div>
      <equipment-details :equipment="equipment" />
    </div>
    <appointments-equipment :equipmentId="equipmentId" />
    <maintenance-plans-equipment :equipmentId="equipmentId" />
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import EquipmentDetails from '@/components/precision/equipments/equipments/EquipmentDetails.vue'
import AppointmentsEquipment from '@/components/precision/equipments/appointments/AppointmentsEquipment.vue'
import MaintenancePlansEquipment from '@/components/precision/equipments/maintenancePlansEquipment/MaintenancePlansEquipment.vue'

export default {
  name: 'PrecisionEquipmentDetails',

  components: {
    LoadingData,
    EquipmentDetails,
    AppointmentsEquipment,
    MaintenancePlansEquipment,
  },

  data() {
    return {
      loadData: true,
      equipmentId: this.$route.params.equipmentId,
      equipment: {},
    }
  },

  methods: {
    async getEquipment() {
      try {
        this.equipment = await this.$store.dispatch(
          'getEquipment',
          this.equipmentId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getEquipment()
  },
}
</script>
