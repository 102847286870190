<template>
  <div class="card">
    <div class="card-header">
      <h6 class="font-weight-light">Status das Solicitações</h6>
    </div>
    <div class="card-body">
      <div class="row text-center">
        <div class="col-4">
          <p>
            <i class="fas fa-list-ul fa-3x d-inline-block text-yellow" />
          </p>
          <h5 class="font-weight-semibold mb-0">
            {{ requestPendents }}
          </h5>
          <span class="text-muted font-size-sm">Pendentes</span>
        </div>
        <div class="col-4">
          <p>
            <i class="fas fa-tasks fa-3x d-inline-block text-primary" />
          </p>
          <h5 class="font-weight-semibold mb-0">
            {{ requestInProgress }}
          </h5>
          <span class="text-muted font-size-sm">Em Andamento</span>
        </div>
        <div class="col-4">
          <p>
            <i class="fas fa-check fa-3x d-inline-block text-success" />
          </p>
          <h5 class="font-weight-semibold mb-0">
            {{ requestComplete }}
          </h5>
          <span class="text-muted font-size-sm">Concluídas</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusRequest',

  props: {
    requestPendents: {
      type: String,
      required: true,
    },
    requestInProgress: {
      type: String,
      required: true,
    },
    requestComplete: {
      type: String,
      required: true,
    },
  },
}
</script>
