<template>
  <div>
    <div v-if="dataReport.bySampler.length > 0">
      <div class="card">
        <div class="card-body">
          <report-buttons
            :nameExcel="'Relatorio_Produtiviade_dos_Amostradores_Resumo'"
            :worksheetExcel="titleBySampler"
            :fieldsExcel="fieldsBySamplerExcel"
            :dataExcel="dataReport.bySampler"
            :divElementPrint="'printReportBySampler'"
          />
          <div id="printReportBySampler">
            <report-header :title="titleBySampler" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnBySampler === 'sampler' &&
                          dataReport.sortTypeBySampler === 'asc',
                        sorting_desc:
                          dataReport.sortColumnBySampler === 'sampler' &&
                          dataReport.sortTypeBySampler === 'desc',
                      }"
                      @click="sortColumnBySampler('sampler')"
                    >
                      Amostrador
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnBySampler === 'hectare' &&
                          dataReport.sortTypeBySampler === 'asc',
                        sorting_desc:
                          dataReport.sortColumnBySampler === 'hectare' &&
                          dataReport.sortTypeBySampler === 'desc',
                      }"
                      @click="sortColumnBySampler('hectare')"
                    >
                      Coletado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(report, index) in dataReport.bySampler"
                    :key="index"
                  >
                    <td>{{ report.sampler }}</td>
                    <td>{{ report.hectareToCurrencyBr }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>{{ dataReport.totals.bySampler }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <report-buttons
            :nameExcel="'Relatorio_Produtiviade_dos_Amostradores_Detalhe'"
            :worksheetExcel="titleDetails"
            :fieldsExcel="fieldsDetailsExcel"
            :dataExcel="dataReport.details"
            :divElementPrint="'printReportDetails'"
          />
          <div id="printReportDetails">
            <report-header :title="titleDetails" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'sampler' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'sampler' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('sampler')"
                    >
                      Amostrador
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'client' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'client' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('client')"
                    >
                      Cliente
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'farm' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'farm' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('farm')"
                    >
                      Fazenda
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'area' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'area' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('area')"
                    >
                      Talhão
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'hectare' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'hectare' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('hectare')"
                    >
                      Hectare
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'date' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'date' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('date')"
                    >
                      Data da Coleta
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(report, index) in dataReport.details"
                    :key="index"
                  >
                    <td>{{ report.sampler }}</td>
                    <td>{{ report.client }}</td>
                    <td>{{ report.farm }}</td>
                    <td>{{ report.area }}</td>
                    <td>{{ report.hectareToCurrencyBr }}</td>
                    <td>{{ report.dateToCurrencyBr }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ dataReport.totals.details }}</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <report-buttons
            :nameExcel="'Relatorio_Produtiviade_dos_Amostradores_Por_Grid'"
            :worksheetExcel="titleByGrid"
            :fieldsExcel="fieldsByGridExcel"
            :dataExcel="dataReport.byGrid"
            :divElementPrint="'printReportByGrid'"
          />
          <div id="printReportByGrid">
            <report-header :title="titleByGrid" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnByGrid === 'sampler' &&
                          dataReport.sortTypeByGrid === 'asc',
                        sorting_desc:
                          dataReport.sortColumnByGrid === 'sampler' &&
                          dataReport.sortTypeByGrid === 'desc',
                      }"
                      @click="sortColumnByGrid('sampler')"
                    >
                      Amostrador
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnByGrid === 'numberGrid' &&
                          dataReport.sortTypeByGrid === 'asc',
                        sorting_desc:
                          dataReport.sortColumnByGrid === 'numberGrid' &&
                          dataReport.sortTypeByGrid === 'desc',
                      }"
                      @click="sortColumnByGrid('numberGrid')"
                    >
                      Grid
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnByGrid === 'hectare' &&
                          dataReport.sortTypeByGrid === 'asc',
                        sorting_desc:
                          dataReport.sortColumnByGrid === 'hectare' &&
                          dataReport.sortTypeByGrid === 'desc',
                      }"
                      @click="sortColumnByGrid('hectare')"
                    >
                      Coletado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, index) in dataReport.byGrid" :key="index">
                    <td>{{ report.sampler }}</td>
                    <td>{{ report.numberGrid }}</td>
                    <td>{{ report.hectareToCurrencyBr }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>-</td>
                    <td>{{ dataReport.totals.byGrid }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <report-not-found v-else />
  </div>
</template>

<script>
import sortArray from 'sort-array'
import ReportButtons from '@/components/precision/report/ReportButtons.vue'
import ReportHeader from '@/components/precision/report/ReportHeader.vue'
import ReportNotFound from '@/components/precision/report/ReportNotFound.vue'

export default {
  name: 'ReportProductivityOfSamplers',

  props: {
    dataReport: {
      type: Object,
      required: true,
    },
  },

  components: {
    ReportHeader,
    ReportButtons,
    ReportNotFound,
  },

  data() {
    return {
      titleBySampler: 'Produt. Amostradores (Resumo)',
      titleDetails: 'Produt. Amostradores (Detalhe)',
      titleByGrid: 'Produt. Amostradores (Grid)',
      fieldsBySamplerExcel: {
        Amostrador: 'sampler',
        Coletado: 'hectare',
      },
      fieldsDetailsExcel: {
        'Amostrador': 'sampler',
        'Cliente': 'client',
        'Fazenda': 'farm',
        'Talhão': 'area',
        'Hectare': 'hectare',
        'Data Coleta': 'dateToCurrencyBr',
      },
      fieldsByGridExcel: {
        Amostrador: 'sampler',
        Grid: 'numberGrid',
        Coletado: 'hectare',
      },
    }
  },

  methods: {
    sortColumnBySampler(column) {
      this.dataReport.sortColumnBySampler = column
      this.dataReport.sortTypeBySampler =
        this.dataReport.sortTypeBySampler === '' ||
        this.dataReport.sortTypeBySampler === 'desc'
          ? 'asc'
          : 'desc'

      sortArray(this.dataReport.bySampler, {
        by: this.dataReport.sortColumnBySampler,
        order: this.dataReport.sortTypeBySampler,
      })
    },

    sortColumnDetails(column) {
      this.dataReport.sortColumnDetails = column
      this.dataReport.sortTypeDetails =
        this.dataReport.sortTypeDetails === '' ||
        this.dataReport.sortTypeDetails === 'desc'
          ? 'asc'
          : 'desc'

      sortArray(this.dataReport.details, {
        by: this.dataReport.sortColumnDetails,
        order: this.dataReport.sortTypeDetails,
      })
    },

    sortColumnByGrid(column) {
      this.dataReport.sortColumnByGrid = column
      this.dataReport.sortTypeByGrid =
        this.dataReport.sortTypeByGrid === '' ||
        this.dataReport.sortTypeByGrid === 'desc'
          ? 'asc'
          : 'desc'

      sortArray(this.dataReport.byGrid, {
        by: this.dataReport.sortColumnByGrid,
        order: this.dataReport.sortTypeByGrid,
      })
    },
  },
}
</script>
