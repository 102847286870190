<template>
  <div class="content">
    <create-edit-laboratory-modal
      v-if="showCreateEditLaboratoryModal"
      :laboratoryId="laboratoryIdSelected"
      @closeModal="closeCreateEditLaboratoryModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Laboratórios
        </h5>
        <div v-if="getModuleUserByCode('2.04.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Laboratório"
            @click="createEditLaboratory()"
          >
            Novo Laboratório
          </button>
        </div>
      </div>
      <list-laboratorys
        :laboratorys="laboratorys"
        @editLaboratory="createEditLaboratory"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditLaboratoryModal from '@/components/precision/laboratorys/CreateEditLaboratoryModal.vue'
import ListLaboratorys from '@/components/precision/laboratorys/ListLaboratorys.vue'

export default {
  name: 'PrecisionLaboratorys',

  components: {
    LoadingData,
    CreateEditLaboratoryModal,
    ListLaboratorys,
  },

  data() {
    return {
      loadData: true,
      showCreateEditLaboratoryModal: false,
      laboratorys: [],
      laboratoryIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getLaboratorys() {
      try {
        this.laboratorys = await this.$store.dispatch('getLaboratorys')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditLaboratory(laboratoryId) {
      this.laboratoryIdSelected = laboratoryId || null
      this.showCreateEditLaboratoryModal = true
    },

    closeCreateEditLaboratoryModal(loadListLaboratorys) {
      if (loadListLaboratorys) {
        this.loadData = true
        this.getLaboratorys()
      }
      this.showCreateEditLaboratoryModal = false
    },
  },

  mounted() {
    this.getLaboratorys()
  },
}
</script>
