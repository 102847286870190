<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Relatórios
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="reports/overview" title="Acessar">
              <div class="card h-100" title="Visão Geral">
                <div class="card-body text-center">
                  <i
                    class="icon-file-text2 icon-2x text-primary border-primary border-3 rounded-pill p-3 my-1"
                  >
                  </i>
                  <h5 class="card-title text-primary mt-2 mb-0">Visão Geral</h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="reports/collection-by-client" title="Acessar">
              <div class="card h-100" title="Coleta por Cliente">
                <div class="card-body text-center">
                  <i
                    class="fas fa-motorcycle fa-2x text-purple border-purple border-3 rounded-pill p-3 my-1"
                  />
                  <h5 class="card-title text-purple mt-2 mb-0">
                    Coleta por Cliente
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="reports/productivity-of-samplers" title="Acessar">
              <div class="card h-100" title="Produtividade dos Amostradores">
                <div class="card-body text-center">
                  <i
                    class="fas fa-chart-bar fa-2x text-secondary border-secondary border-3 rounded-pill p-3 my-1"
                  />
                  <h5 class="card-title text-secondary mt-2 mb-0">
                    Produtividade dos Amostradores
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="reports/destination-of-samples" title="Acessar">
              <div class="card h-100" title="Destino das amostras">
                <div class="card-body text-center">
                  <i
                    class="fas fa-paper-plane fa-2x text-indigo border-indigo border-3 rounded-pill p-3 my-1"
                  />
                  <h5 class="card-title text-indigo mt-2 mb-0">
                    Destino das Amostras
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="reports/status-plots" title="Acessar">
              <div class="card h-100" title="Status dos talhões">
                <div class="card-body text-center">
                  <i
                    class="far fa-check-circle fa-2x text-success border-success border-3 rounded-pill p-3 my-1"
                  />
                  <h5 class="card-title text-success mt-2 mb-0">
                    Status dos Talhões
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="reports/farm-location" title="Acessar">
              <div class="card h-100" title="Localização das Fazendas">
                <div class="card-body text-center">
                  <i
                    class="fas fa-map-marked-alt fa-2x text-info border-info border-3 rounded-pill p-3 my-1"
                  />
                  <h5 class="card-title text-info mt-2 mb-0">
                    Localização das Fazendas
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="reports/area-location" title="Acessar">
              <div class="card h-100" title="Localização das Áreas">
                <div class="card-body text-center">
                  <i
                    class="fas fa-globe-americas fa-2x text-teal border-teal border-3 rounded-pill p-3 my-1"
                  />
                  <h5 class="card-title text-teal mt-2 mb-0">
                    Localização das Áreas
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrecisionReport',
}
</script>
