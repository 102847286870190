<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/reports" title="Voltar">
            Relatórios
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Destino das Amostras
        </h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="search">
          <div class="form-row">
            <div class="form-group col-12 col-sm-4">
              <label>Data Inicial: <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control"
                :class="{ 'is-invalid': $v.filters.startDate.$error }"
                v-model="$v.filters.startDate.$model"
              />
              <span
                v-if="!$v.filters.startDate.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Data final: <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control"
                :class="{ 'is-invalid': $v.filters.endDate.$error }"
                v-model="$v.filters.endDate.$model"
              />
              <span
                v-if="!$v.filters.endDate.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Laboratório: </label>
              <select class="form-control" v-model="filters.laboratoryId">
                <option value="">Selecione um Laboratório</option>
                <option
                  v-for="laboratory in laboratorys"
                  :key="laboratory.id"
                  :value="laboratory.id"
                  :title="laboratory.name"
                >
                  {{ laboratory.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-right">
            <button
              type="submit"
              class="btn btn-primary"
              title="Buscar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Buscar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <report-destination-of-samples
      v-if="dataReport.byLaboratory"
      :dataReport="dataReport"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { numberToCurrencyBr } from '@/utils/strings'
import { dateSqlToDateBR } from '@/utils/date'
import ReportDestinationOfSamples from '@/components/precision/report/ReportDestinationOfSamples.vue'

export default {
  name: 'PrecisionReportDestinationOfSamples',

  components: {
    ReportDestinationOfSamples,
  },

  data() {
    return {
      laboratorys: [],
      filters: {
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: '',
        laboratoryId: '',
      },
      dataReport: {
        byLaboratory: null,
        details: null,
      },
      disableButton: false,
    }
  },

  methods: {
    async getLaboratorys() {
      try {
        this.laboratorys = await this.$store.dispatch('getLaboratorys', {
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async search() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'getReportDestinationOfSamples',
            this.filters,
          )
          this.dataReport = this.formatData(response)
          this.disableButton = false
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    formatData(data) {
      return {
        byLaboratory: data.byLaboratory.map((element) => {
          return {
            ...element,
            hectareToCurrencyBr: numberToCurrencyBr(element.hectare),
            hectare: Number(element.hectare),
          }
        }),
        details: data.details.map((element) => {
          return {
            ...element,
            hectareToCurrencyBr: numberToCurrencyBr(element.hectare),
            hectare: Number(element.hectare),
            dateToCurrencyBr: dateSqlToDateBR(element.date),
          }
        }),
        totals: {
          byLaboratory: numberToCurrencyBr(data.totals.byLaboratory),
          details: numberToCurrencyBr(data.totals.details),
        },
        sortColumnByLaboratory: '',
        sortTypeByLaboratory: '',
        sortColumnDetails: '',
        sortTypeDetails: '',
      }
    },
  },

  validations: {
    filters: {
      startDate: {
        required,
      },
      endDate: {
        required,
      },
    },
  },

  mounted() {
    this.getLaboratorys()
  },
}
</script>
