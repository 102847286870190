<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/reports" title="Voltar">
            Relatórios
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Coleta por Cliente
        </h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="search">
          <div class="form-row">
            <div class="form-group col-12 col-sm-4">
              <label>Safra: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.filters.harvestId.$error }"
                v-model="$v.filters.harvestId.$model"
              >
                <option value="">Selecione a Safra</option>
                <option
                  v-for="harvest in harvests"
                  :key="harvest.id"
                  :value="harvest.id"
                  :title="harvest.name"
                >
                  {{ harvest.name }}
                </option>
              </select>
              <span
                v-if="!$v.filters.harvestId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Data Inicial: <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control"
                :class="{ 'is-invalid': $v.filters.startDate.$error }"
                v-model="$v.filters.startDate.$model"
              />
              <span
                v-if="!$v.filters.startDate.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Data final: <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control"
                :class="{ 'is-invalid': $v.filters.endDate.$error }"
                v-model="$v.filters.endDate.$model"
              />
              <span
                v-if="!$v.filters.endDate.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Cliente: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.filters.clientId.$error }"
                v-model="$v.filters.clientId.$model"
              >
                <option value="">Selecione um Cliente</option>
                <option
                  v-for="client in clients"
                  :key="client.id"
                  :value="client.id"
                  :title="client.name"
                >
                  {{ client.name }}
                </option>
              </select>
              <span
                v-if="!$v.filters.clientId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Fazenda:</label>
              <select class="form-control" v-model="filters.farmId">
                <option value="">Selecione uma Fazenda</option>
                <option
                  v-for="farm in farms"
                  :key="farm.id"
                  :value="farm.id"
                  :title="farm.name"
                >
                  {{ farm.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Talhão:</label>
              <select class="form-control" v-model="filters.areaId">
                <option value="">Selecione um Talhão</option>
                <option
                  v-for="area in areas"
                  :key="area.id"
                  :value="area.id"
                  :title="area.name"
                >
                  {{ area.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-right">
            <button
              type="submit"
              class="btn btn-primary"
              title="Buscar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Buscar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <report-collection-by-client v-if="dataReport" :dataReport="dataReport" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { numberToCurrencyBr } from '@/utils/strings'
import { dateSqlToDateBR } from '@/utils/date'
import ReportCollectionByClient from '@/components/precision/report/ReportCollectionByClient.vue'

export default {
  name: 'PrecisionReportCollectionByClient',

  components: {
    ReportCollectionByClient,
  },

  data() {
    return {
      harvests: [],
      clients: [],
      farms: [],
      areas: [],
      filters: {
        harvestId: '',
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: '',
        clientId: '',
        farmId: '',
        areaId: '',
      },
      dataReport: null,
      disableButton: false,
    }
  },

  watch: {
    'filters.harvestId': {
      handler(newVal, oldVal) {
        this.filters.clientId = ''
      },
    },

    'filters.clientId': {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.getFarms()
        }
        this.farms = null
        this.filters.farmId = ''
      },
    },

    'filters.farmId': {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.getAreas()
        }
        this.areas = null
        this.filters.areaId = ''
      },
    },
  },

  methods: {
    async getHarvests() {
      try {
        this.harvests = await this.$store.dispatch('getHarvests')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getClients() {
      try {
        this.clients = await this.$store.dispatch('getClients', {
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getFarms() {
      try {
        this.farms = await this.$store.dispatch('getFarms', {
          clientId: this.filters.clientId,
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getAreas() {
      try {
        this.areas = await this.$store.dispatch('getAreas', {
          clientId: this.filters.clientId,
          farmId: this.filters.farmId,
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async search() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'getReportCollectionByClient',
            this.filters,
          )
          this.dataReport = this.formatData(response)
          this.disableButton = false
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    formatData(data) {
      return {
        client: data.client,
        report: data.report.map((element) => {
          return {
            ...element,
            hectareToCurrencyBr: numberToCurrencyBr(element.hectare),
            hectare: Number(element.hectare),
            lastDateToCurrencyBr: dateSqlToDateBR(element.lastDate),
          }
        }),
        total: numberToCurrencyBr(data.total),
        sortColumn: '',
        sortType: '',
      }
    },
  },

  validations: {
    filters: {
      harvestId: {
        required,
      },
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      clientId: {
        required,
      },
    },
  },

  mounted() {
    this.getHarvests()
    this.getClients()
  },
}
</script>
