<template>
  <div
    id="modalAddSampler"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Vincular Amostrador</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Colaborador: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.sampler.userId.$error }"
                  v-model="$v.sampler.userId.$model"
                >
                  <option value="">Selecione o Colaborador</option>
                  <option
                    v-for="user in users"
                    :key="user.id"
                    :value="user.id"
                    title="user.name"
                  >
                    {{ user.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.sampler.userId.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Vincular Colaborador"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Vincular</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddSamplerModal',

  data() {
    return {
      sampler: {
        userId: '',
      },
      disableButton: false,
      users: null,
    }
  },

  methods: {
    async getUsersNotSamplers() {
      try {
        this.users = await this.$store.dispatch('getUsersNotSamplers')
        window.$('#modalAddSampler').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalAddSampler').modal('hide')
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'saveSampler',
            this.sampler,
          )
          this.disableButton = false
          window.$('#modalAddSampler').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    sampler: {
      userId: {
        required,
      },
    },
  },

  mounted() {
    this.getUsersNotSamplers()
  },
}
</script>
