<template>
  <div class="card">
    <div class="card-header">
      <h6 class="font-weight-light">Medições Por Data</h6>
    </div>
    <div class="card-body">
      <v-chart class="chart" :option="optionReder" />
    </div>
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import { transformDateInObject } from '@/utils/date'

use([
  CanvasRenderer,
  GridComponent,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomSliderComponent,
  DataZoomInsideComponent,
])

export default {
  name: 'DateChart',

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  components: {
    VChart,
  },

  data() {
    return {
      optionReder: null,
    }
  },

  methods: {
    generateOptionToChart() {
      this.optionReder = {
        xAxis: {
          type: 'category',
          data: transformDateInObject(this.option.date),
        },
        yAxis: {
          type: 'value',
          name: 'Área Medida (ha)',
        },
        tooltip: {
          trigger: 'item',
        },
        dataZoom: [
          {
            type: 'inside',
          },
          {
            start: 0,
            end: 10,
          },
        ],
        series: [
          {
            data: this.option.value,
            type: 'line',
          },
        ],
      }
    },
  },

  mounted() {
    this.generateOptionToChart()
  },
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
