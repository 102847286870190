<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Criado por</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="sampler in samplers" :key="sampler.id">
        <td>{{ sampler.name }}</td>
        <td>{{ createdUpdatedUser(sampler.createdBy, sampler.createdAt) }}</td>
        <td class="text-center">
          <button
            v-if="getModuleUserByCode('2.02.003')"
            type="button"
            class="btn btn-outline-danger btn-icon rounded-pill"
            title="Remover Amostrador"
            @click="$emit('removeSampler', sampler.id)"
          >
            <i class="fas fa-trash-alt" />
          </button>
          <span v-else>-</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'ListSamplers',

  props: {
    samplers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListSampler',
        columnDefs: [
          {
            orderable: false,
            width: 100,
            targets: [2],
          },
        ],
      },
      createdUpdatedUser,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
