<template>
  <div>
    <hr />
    <div class="card card-body">
      <form @submit.prevent="getDashboard">
        <div class="form-row">
          <div class="form-group col-12 col-sm-5 mb-2">
            <label>Safra:</label>
            <select class="form-control" v-model="filters.harvestId">
              <option value="">Selecione a Safra</option>
              <option
                v-for="harvest in harvests"
                :key="harvest.id"
                :value="harvest.id"
                :title="harvest.name"
              >
                {{ harvest.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-12 col-sm-5 mb-2">
            <label>Estado:</label>
            <select class="form-control" v-model="filters.stateId">
              <option value="">Selecione um Estado</option>
              <option
                v-for="state in states"
                :key="state.id"
                :value="state.id"
                :title="`${state.uf} - ${state.name}`"
              >
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-12 col-sm-2 mb-2">
            <button
              type="submit"
              class="btn btn-primary w-100 mt-4"
              title="Buscar"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Buscar</span>
            </button>
          </div>
        </div>
      </form>
      <span class="mt-2">
        <em>Informação</em>: Se não for selecionando nenhum filtro, será
        considerado a última safra cadastrada e todos os estados.
      </span>
    </div>
    <loading-data v-if="loadData" />
    <div v-else>
      <h4 class="font-weight-light">Safra: {{ dashboardData.harvest.name }}</h4>
      <status-request
        :requestPendents="dashboardData.requestPendents"
        :requestInProgress="dashboardData.requestInProgress"
        :requestComplete="dashboardData.requestComplete"
      />
      <div class="row">
        <div class="col-12 col-md-8">
          <date-chart :option="dashboardData.mesasuresByDate" />
        </div>
        <div class="col-12 col-md-4">
          <status-chart :option="dashboardData.measuresByStatus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import StatusRequest from '@/components/precision/home/StatusRequest.vue'
import DateChart from '@/components/precision/home/DateChart.vue'
import StatusChart from '@/components/precision/home/StatusChart.vue'

export default {
  name: 'Graphics',

  components: {
    LoadingData,
    StatusRequest,
    DateChart,
    StatusChart,
  },

  data() {
    return {
      loadData: true,
      harvests: [],
      states: [],
      filters: {
        harvestId: '',
        stateId: '',
      },
      dashboardData: {},
      disableButton: false,
    }
  },

  methods: {
    async getHarvests() {
      try {
        this.harvests = await this.$store.dispatch('getHarvests')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getDashboard() {
      try {
        this.loadData = true
        this.dashboardData = await this.$store.dispatch(
          'getDashboard',
          this.filters,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.loadData = false
      }
    },
  },

  mounted() {
    this.getHarvests()
    this.getStates()
    this.getDashboard()
  },
}
</script>

<style scoped>
@media (max-width: 576px) {
  button {
    margin-top: 10px !important;
  }
}
</style>
