<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Unidade de controle</th>
        <th>Periodicidade</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="maintenancePlan in maintenancePlans" :key="maintenancePlan.id">
        <td>{{ maintenancePlan.name }}</td>
        <td>{{ maintenancePlan.controlUnit }}</td>
        <td>{{ maintenancePlan.frequency }}</td>
        <td
          class="text-center"
          v-html="statusTranslate(maintenancePlan.status)"
        />
        <td class="text-center">
          <button
            v-if="getModuleUserByCode('2.06.003')"
            type="button"
            class="btn btn-outline-warning btn-icon rounded-pill"
            title="Editar"
            @click="$emit('editMaintenancePlan', maintenancePlan.id)"
          >
            <i class="icon-pencil" />
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-icon rounded-pill ml-2"
            title="Detalhes"
            @click="$emit('detailsMaintenancePlan', maintenancePlan.id)"
          >
            <i class="far fa-file-alt" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { statusTranslate } from '@/utils/strings'

export default {
  name: 'ListMaintenancePlans',

  props: {
    maintenancePlans: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListMaintenancePlans',
        columnDefs: [
          {
            orderable: false,
            width: 130,
            targets: [4],
          },
        ],
      },
      statusTranslate,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
