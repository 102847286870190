<template>
  <div>
    <div v-if="dataReport.report.length > 0">
      <div class="card">
        <div class="card-body">
          <report-buttons
            :nameExcel="'Relatorio_Coleta_Por_Cliente'"
            :worksheetExcel="title"
            :fieldsExcel="fieldsExcel"
            :dataExcel="dataReport.report"
            :divElementPrint="'printReport'"
          />
          <div id="printReport">
            <report-header :title="title" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th colspan="4">
                      <strong>Cliente:</strong> {{ dataReport.client.name }}
                    </th>
                    <th colspan="2">
                      <strong>Contato:</strong> {{ dataReport.client.phone }}
                      {{ dataReport.client.email }}
                    </th>
                  </tr>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'laboratory' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'laboratory' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('laboratory')"
                    >
                      Laboratório
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'farm' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'farm' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('farm')"
                    >
                      Fazenda
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'area' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'area' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('area')"
                    >
                      Talhão
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'hectare' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'hectare' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('hectare')"
                    >
                      Área
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'samples' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'samples' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('samples')"
                    >
                      Amostradores
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'lastDate' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'lastDate' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('lastDate')"
                    >
                      Data
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, index) in dataReport.report" :key="index">
                    <td>{{ report.laboratory }}</td>
                    <td>{{ report.farm }}</td>
                    <td>{{ report.area }}</td>
                    <td>{{ report.hectareToCurrencyBr }}</td>
                    <td>{{ report.samples }}</td>
                    <td>{{ report.lastDateToCurrencyBr }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ dataReport.total }}</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <report-not-found v-else />
  </div>
</template>

<script>
import sortArray from 'sort-array'
import ReportButtons from '@/components/precision/report/ReportButtons.vue'
import ReportHeader from '@/components/precision/report/ReportHeader.vue'
import ReportNotFound from '@/components/precision/report/ReportNotFound.vue'

export default {
  name: 'ReportCollectionByClient',

  props: {
    dataReport: {
      type: Object,
      required: true,
    },
  },

  components: {
    ReportHeader,
    ReportButtons,
    ReportNotFound,
  },

  data() {
    return {
      title: `Coleta por Cliente - ${this.dataReport.client.name}`,
      fieldsExcel: {
        'Laboratório': 'laboratory',
        'Fazenda': 'farm',
        'Talhão': 'area',
        'Área': 'hectare',
        'Amostradores': 'samples',
        'Data Coleta': 'lastDateToCurrencyBr',
      },
    }
  },

  methods: {
    sortColumn(column) {
      this.dataReport.sortColumn = column
      this.dataReport.sortType =
        this.dataReport.sortType === '' || this.dataReport.sortType === 'desc'
          ? 'asc'
          : 'desc'

      sortArray(this.dataReport.report, {
        by: this.dataReport.sortColumn,
        order: this.dataReport.sortType,
      })
    },
  },
}
</script>
