<template>
  <div>
    <add-maintenance-plan-equipment-modal
      v-if="showAddMaintenancePlanEquipmentModal"
      :equipmentId="equipmentId"
      @closeModal="closeAddRegisterHistoricMaintenancePlanEquipmentModal"
    />
    <register-maintenance-plan-equipment-modal
      v-if="showRegisterMaintenancePlanEquipmentModal"
      :equipmentId="equipmentId"
      :maintenancePlanId="maintenancePlanIdSelected"
      @closeModal="closeAddRegisterHistoricMaintenancePlanEquipmentModal"
    />
    <historic-maintenance-plan-equipment-modal
      v-if="showHistoricMaintenancePlanEquipmentModal"
      :equipmentId="equipmentId"
      :maintenancePlanId="maintenancePlanIdSelected"
      @closeModal="closeAddRegisterHistoricMaintenancePlanEquipmentModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">Planos de Manutenção</h5>
        <div v-if="getModuleUserByCode('2.06.005')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Associar um Plano de Manutenção ao Equipamento"
            @click="showAddMaintenancePlanEquipmentModal = true"
          >
            Associar Plano de Manutenção
          </button>
        </div>
      </div>
      <list-maintenance-plans-equipment
        :maintenancePlansEquipament="maintenancePlansEquipament"
        @registerMaintenance="registerMaintenance"
        @historicMaintenance="historicMaintenance"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import AddMaintenancePlanEquipmentModal from '@/components/precision/equipments/maintenancePlansEquipment/AddMaintenancePlanEquipmentModal.vue'
import RegisterMaintenancePlanEquipmentModal from '@/components/precision/equipments/maintenancePlansEquipment/RegisterMaintenancePlanEquipmentModal.vue'
import HistoricMaintenancePlanEquipmentModal from '@/components/precision/equipments/maintenancePlansEquipment/HistoricMaintenancePlanEquipmentModal.vue'
import ListMaintenancePlansEquipment from '@/components/precision/equipments/maintenancePlansEquipment/ListMaintenancePlansEquipment.vue'

export default {
  name: 'MaintenancePlansEquipment',

  components: {
    LoadingData,
    AddMaintenancePlanEquipmentModal,
    RegisterMaintenancePlanEquipmentModal,
    HistoricMaintenancePlanEquipmentModal,
    ListMaintenancePlansEquipment,
  },

  props: {
    equipmentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      showAddMaintenancePlanEquipmentModal: false,
      showRegisterMaintenancePlanEquipmentModal: false,
      showHistoricMaintenancePlanEquipmentModal: false,
      maintenancePlansEquipament: [],
      planIdSelected: null,
    }
  },

  computed: {
    ...mapGetters([
      'getModuleUserByCode',
      'reloadListMaintenancePlansEquipment',
    ]),
  },

  watch: {
    reloadListMaintenancePlansEquipment: {
      handler(newVal, oldVal) {
        if (newVal === true) {
          this.getMaintenancePlansEquipament()
          this.$store.dispatch('setReloadListMaintenancePlansEquipment', false)
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getMaintenancePlansEquipament() {
      try {
        this.maintenancePlansEquipament = await this.$store.dispatch(
          'getMaintenancePlansEquipment',
          this.equipmentId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    registerMaintenance(maintenancePlanId) {
      this.maintenancePlanIdSelected = maintenancePlanId
      this.showRegisterMaintenancePlanEquipmentModal = true
    },

    historicMaintenance(maintenancePlanId) {
      this.maintenancePlanIdSelected = maintenancePlanId
      this.showHistoricMaintenancePlanEquipmentModal = true
    },

    closeAddRegisterHistoricMaintenancePlanEquipmentModal(
      loadListMaintenancePlans,
    ) {
      if (loadListMaintenancePlans) {
        this.loadData = true
        this.getMaintenancePlansEquipament()
      }
      this.showAddMaintenancePlanEquipmentModal = false
      this.showRegisterMaintenancePlanEquipmentModal = false
      this.showHistoricMaintenancePlanEquipmentModal = false
    },
  },

  mounted() {
    this.getMaintenancePlansEquipament()
  },
}
</script>
