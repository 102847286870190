<template>
  <div class="card">
    <div class="card-body">
      <p class="text-center mb-0">Nenhum resultado encontrado</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportNotFound',
}
</script>
