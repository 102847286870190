<template>
  <div>
    <div v-if="dataReport.report.length > 0" class="card">
      <div class="card-body">
        <div>
          <report-buttons
            :nameExcel="'Relatorio_Visao_Geral'"
            :worksheetExcel="title"
            :fieldsExcel="fieldsExcel"
            :dataExcel="dataReport.report"
            :divElementPrint="'printReport'"
          />
          <div id="printReport">
            <report-header :title="title" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'laboratory' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'laboratory' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('laboratory')"
                    >
                      Laboratório
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'client' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'client' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('client')"
                    >
                      Cliente
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'farm' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'farm' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('farm')"
                    >
                      Fazenda
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'area' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'area' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('area')"
                    >
                      Talhão
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'hectare' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'hectare' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('hectare')"
                    >
                      Área
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'colected' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'colected' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('colected')"
                    >
                      Coletado
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'samples' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'samples' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('samples')"
                    >
                      Amostrador
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'date' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'date' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('date')"
                    >
                      Coleta
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'status' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'status' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('status')"
                    >
                      Status
                    </th>
                    <th>Observação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, index) in dataReport.report" :key="index">
                    <td>{{ report.laboratory ? report.laboratory : '-' }}</td>
                    <td>{{ report.client }}</td>
                    <td>{{ report.farm }}</td>
                    <td>{{ report.area }}</td>
                    <td>{{ report.hectareToCurrencyBr }}</td>
                    <td>{{ report.colectedToCurrencyBr }}</td>
                    <td>{{ report.samples }}</td>
                    <td>{{ report.dateToCurrencyBr }}</td>
                    <td>{{ report.status }}</td>
                    <td>{{ report.observation }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ dataReport.totals.area }}</td>
                    <td>{{ dataReport.totals.coleted }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <report-not-found v-else />
  </div>
</template>

<script>
import sortArray from 'sort-array'
import ReportButtons from '@/components/precision/report/ReportButtons.vue'
import ReportHeader from '@/components/precision/report/ReportHeader.vue'
import ReportNotFound from '@/components/precision/report/ReportNotFound.vue'

export default {
  name: 'ReportOverview',

  props: {
    dataReport: {
      type: Object,
      required: true,
    },
  },

  components: {
    ReportHeader,
    ReportButtons,
    ReportNotFound,
  },

  data() {
    return {
      title: 'Visão Geral',
      fieldsExcel: {
        'Laboratório': 'laboratory',
        'Cliente': 'client',
        'Fazenda': 'farm',
        'Talhão': 'area',
        'Área (ha)': 'hectare',
        'Coletado': 'colected',
        'Amostrador': 'samples',
        'Coleta': 'dateToCurrencyBr',
        'Status': 'status',
        'Observação': 'observation',
      },
    }
  },

  methods: {
    sortColumn(column) {
      this.dataReport.sortColumn = column
      this.dataReport.sortType =
        this.dataReport.sortType === '' || this.dataReport.sortType === 'desc'
          ? 'asc'
          : 'desc'

      sortArray(this.dataReport.report, {
        by: this.dataReport.sortColumn,
        order: this.dataReport.sortType,
      })
    },
  },
}
</script>
