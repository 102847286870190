<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Plano</th>
        <th>Unidade de controle</th>
        <th>Periodicidade</th>
        <th>Data Última Manutenção</th>
        <th>Última Manutenção</th>
        <th>Próxima Manutenção</th>
        <th>Quanto Falta Para a Próxima Manutenção</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="maintenancePlan in maintenancePlansEquipament"
        :key="maintenancePlan.id"
      >
        <td>{{ maintenancePlan.name }}</td>
        <td>{{ maintenancePlan.controlUnit }}</td>
        <td>{{ maintenancePlan.frequency }}</td>
        <td>{{ dateSqlToDateBR(maintenancePlan.dateLastMaintenance) }}</td>
        <td>{{ maintenancePlan.valueLastMaintenance }}</td>
        <td>{{ maintenancePlan.valueNextMaintenance }}</td>
        <td :class="defineClass(maintenancePlan.missingForNextMaintenance)">
          {{ maintenancePlan.missingForNextMaintenance }}
        </td>
        <td class="text-center">
          <span v-if="getModuleUserByCode('2.06.005')">
            <button
              type="button"
              class="btn btn-outline-info btn-icon rounded-pill"
              title="Registrar Manutenção"
              @click="
                $emit('registerMaintenance', maintenancePlan.maintenancePlanId)
              "
            >
              <i class="icon-wrench2" />
            </button>
          </span>
          <button
            type="button"
            class="btn btn-outline-primary btn-icon rounded-pill ml-2"
            title="Histórico de Manutenção"
            @click="
              $emit('historicMaintenance', maintenancePlan.maintenancePlanId)
            "
          >
            <i class="far fa-file-alt" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { dateSqlToDateBR } from '@/utils/date'

export default {
  name: 'ListMaintenancePlansEquipment',

  props: {
    maintenancePlansEquipament: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListMaintenancePlansEquipment',
        columnDefs: [
          {
            orderable: false,
            width: 130,
            targets: [7],
          },
        ],
      },
      dateSqlToDateBR,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        order: [[0, 'desc']],
        columnDefs: this.dataTable.columnDefs,
      })
    },

    defineClass(missingForNextMaintenance) {
      if (typeof missingForNextMaintenance === 'string') {
        if (missingForNextMaintenance.includes('Manutenção Atrasada')) {
          return 'text-danger'
        }

        if (
          missingForNextMaintenance.includes('O último apontamento registrado')
        ) {
          return 'text-warning'
        }
      }

      return ''
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
