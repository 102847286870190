<template>
  <div
    id="modalAddSampler"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Adicionar Amostrador</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="addSampler">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-12">
                <label>Amostrador: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.requestSampler.userId.$error }"
                  v-model="$v.requestSampler.userId.$model"
                >
                  <option value="">Selecione um amostrador</option>
                  <option
                    v-for="sampler in samplers"
                    :key="sampler.userId"
                    :value="sampler.userId"
                    title="sampler.name"
                  >
                    {{ sampler.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.requestSampler.userId.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Adicionar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Adicionar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddSamplerModal',

  props: {
    requestId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      samplers: null,
      requestSampler: {
        requestId: this.requestId,
        userId: '',
      },
      disableButton: false,
    }
  },

  methods: {
    async getSamplers() {
      try {
        this.samplers = await this.$store.dispatch(
          'getSamplers',
          this.requestId,
        )
        window.$('#modalAddSampler').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalAddSampler').modal('hide')
        this.$emit('closeModal', false)
      }
    },

    async addSampler() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'addSampleRequest',
            this.requestSampler,
          )
          this.disableButton = false
          window.$('#modalAddSampler').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    requestSampler: {
      userId: {
        required,
      },
    },
  },

  mounted() {
    this.getSamplers()
  },
}
</script>
