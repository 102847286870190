<template>
  <div>
    <create-appointment-equipment-modal
      v-if="showCreateAppointmentEquipmentModal"
      :equipmentId="equipmentId"
      @closeModal="closeCreateAppointmentEquipmentModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">Apontamentos</h5>
        <div v-if="getModuleUserByCode('2.06.004')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Criar Apontamento"
            @click="createAppointment()"
          >
            Criar Apontamento
          </button>
        </div>
      </div>
      <list-appointments-equipment :appointments="appointments" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateAppointmentEquipmentModal from '@/components/precision/equipments/appointments/CreateAppointmentEquipmentModal.vue'
import ListAppointmentsEquipment from '@/components/precision/equipments/appointments/ListAppointmentsEquipment.vue'

export default {
  name: 'AppointmentsEquipment',

  components: {
    LoadingData,
    CreateAppointmentEquipmentModal,
    ListAppointmentsEquipment,
  },

  props: {
    equipmentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      showCreateAppointmentEquipmentModal: false,
      appointments: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getAppointments() {
      try {
        this.appointments = await this.$store.dispatch(
          'getAppointmentsEquipment',
          this.equipmentId,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createAppointment() {
      this.showCreateAppointmentEquipmentModal = true
    },

    closeCreateAppointmentEquipmentModal(loadListAppointments) {
      if (loadListAppointments) {
        this.loadData = true
        this.$store.dispatch('setReloadListMaintenancePlansEquipment', true)
        this.getAppointments()
      }
      this.showCreateAppointmentEquipmentModal = false
    },
  },

  mounted() {
    this.getAppointments()
  },
}
</script>
