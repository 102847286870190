var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"modalAddMaintenancePlanEquipment","tabindex":"-1","data-keyboard":"false","data-backdrop":"static"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(" Associar Plano de Manutenção ao Equipamento ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","title":"Fechar"},on:{"click":function($event){return _vm.$emit('closeModal', false)}}},[_vm._v(" × ")])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-sm-12"},[_vm._m(0),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.equipmentMaintenancePlan.maintenancePlanId.$model),expression:"$v.equipmentMaintenancePlan.maintenancePlanId.$model"}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.$v.equipmentMaintenancePlan.maintenancePlanId.$error,
                },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.equipmentMaintenancePlan.maintenancePlanId, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Selecione o Plano")]),_vm._l((_vm.maintenancePlans),function(maintenancePlan){return _c('option',{key:maintenancePlan.id,domProps:{"value":maintenancePlan.id}},[_vm._v(" "+_vm._s(maintenancePlan.name)+" ")])})],2),(!_vm.$v.equipmentMaintenancePlan.maintenancePlanId.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])]),(Object.keys(_vm.maintenancePlanSelected).length !== 0)?_c('div',{staticClass:"alert alert-primary"},[_c('h5',[_vm._v("Detalhes do Plano Selecionado")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('p',[_c('strong',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(_vm.maintenancePlanSelected.name)+" ")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('p',[_c('strong',[_vm._v("Unidade de controle:")]),_vm._v(" "+_vm._s(_vm.maintenancePlanSelected.controlUnit)+" ")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('p',[_c('strong',[_vm._v("Periodicidade:")]),_vm._v(" "+_vm._s(_vm.maintenancePlanSelected.frequency)+" ")])])]),_c('hr',{staticClass:"my-2"}),_c('h5',[_vm._v("Tarefas")]),_c('ul',_vm._l((_vm.maintenancePlanSelected.tasks),function(task){return _c('li',{key:task.id},[_vm._v(" "+_vm._s(task.name)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-sm-6"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.$v.equipmentMaintenancePlan.dateLastMaintenance.$model
                ),expression:"\n                  $v.equipmentMaintenancePlan.dateLastMaintenance.$model\n                "}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.$v.equipmentMaintenancePlan.dateLastMaintenance.$error,
                },attrs:{"type":"date","placeholder":"Data da Última Manutenção"},domProps:{"value":(
                  _vm.$v.equipmentMaintenancePlan.dateLastMaintenance.$model
                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.equipmentMaintenancePlan.dateLastMaintenance, "$model", $event.target.value)}}}),(
                  !_vm.$v.equipmentMaintenancePlan.dateLastMaintenance.required
                )?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()]),_c('div',{staticClass:"form-group col-sm-6"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(
                  _vm.$v.equipmentMaintenancePlan.valueLastMaintenance.$model
                ),expression:"\n                  $v.equipmentMaintenancePlan.valueLastMaintenance.$model\n                ",modifiers:{"number":true}}],staticClass:"form-control",class:{
                  'is-invalid':
                    _vm.$v.equipmentMaintenancePlan.valueLastMaintenance.$error,
                },attrs:{"type":"number","min":"0","step":"1","placeholder":"Última Manutenção"},domProps:{"value":(
                  _vm.$v.equipmentMaintenancePlan.valueLastMaintenance.$model
                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.equipmentMaintenancePlan.valueLastMaintenance, "$model", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(
                  !_vm.$v.equipmentMaintenancePlan.valueLastMaintenance.required
                )?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" O campo é obrigatório ")]):_vm._e()])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-link",attrs:{"type":"button","data-dismiss":"modal","title":"Cancelar"},on:{"click":function($event){return _vm.$emit('closeModal', false)}}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","title":"Cadastrar","disabled":_vm.$v.$invalid || _vm.disableButton}},[(_vm.disableButton)?_c('span',[_c('i',{staticClass:"icon-spinner2 spinner"})]):_c('span',[_vm._v("Cadastrar")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_vm._v(" Plano de Manutenção: "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_vm._v(" Data da Última Manutenção: "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_vm._v(" Última Manutenção: "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }