<template>
  <div
    id="modalAssociateLaboratory"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Associar Laboratório em cada Talhão</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="associateLaboratory">
          <div class="modal-body">
            <div v-if="areaLaboratory.areas.length > 0">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="applyToAll"
                  v-model="applyToAll"
                />
                <label class="form-check-label" for="applyToAll">
                  Quero aplicar o mesmo laboratório para todos os talhões
                </label>
              </div>

              <div v-if="applyToAll" class="my-3">
                <select class="form-control" v-model="applyToAllLaboratory">
                  <option value="">Selecione um laboratório</option>
                  <option
                    v-for="laboratory in laboratorys"
                    :key="laboratory.id"
                    :value="laboratory.id"
                    title="laboratory.name"
                  >
                    {{ laboratory.name }}
                  </option>
                </select>
              </div>
              <hr />

              <div
                v-for="(area, index) in areaLaboratory.areas"
                :key="area.id"
                class="form-row"
              >
                <div class="col-sm-5">
                  <p><strong>Talhão:</strong> {{ area.name }}</p>
                  <p class="mb-0">
                    <strong>Hectare:</strong> {{ area.hectare }}
                  </p>
                </div>
                <div class="col-sm-7">
                  <select
                    class="form-control"
                    v-model="areaLaboratory.areas[index].laboratoryId"
                  >
                    <option value="">Selecione um laboratório</option>
                    <option
                      v-for="laboratory in laboratorys"
                      :key="laboratory.id"
                      :value="laboratory.id"
                      title="laboratory.name"
                    >
                      {{ laboratory.name }}
                    </option>
                  </select>
                </div>
                <div class="col-12">
                  <hr />
                </div>
              </div>
            </div>
            <p v-else>
              Não existe nenhum talhão para associar laboratório no momento.
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              v-if="areaLaboratory.areas.length > 0"
              type="submit"
              class="btn btn-primary"
              title="Salvar"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Salvar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssociateLaboratoryModal',

  props: {
    requestId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      applyToAll: false,
      applyToAllLaboratory: '',
      areaLaboratory: {
        requestId: this.requestId,
        areas: [],
      },
      laboratorys: {},
      disableButton: false,
    }
  },

  watch: {
    applyToAll: {
      handler(newVal, oldVal) {
        if (newVal === false) {
          this.applyToAllLaboratory = ''
        }
        this.areaLaboratory.areas = this.areaLaboratory.areas.map((area) => {
          return { ...area, laboratoryId: '' }
        })
      },
    },

    applyToAllLaboratory: {
      handler(newVal, oldVal) {
        this.areaLaboratory.areas = this.areaLaboratory.areas.map((area) => {
          return { ...area, laboratoryId: newVal }
        })
      },
    },
  },

  methods: {
    async getRequestAreas() {
      try {
        const response = await this.$store.dispatch(
          'getRequestAreas',
          this.requestId,
        )
        response.forEach((area) => {
          if (area.status === '250' || area.status === '251') {
            this.areaLaboratory.areas.push({
              id: area.id,
              name: area.areaName,
              hectare: area.hectare,
              laboratoryId: area.laboratoryId ? area.laboratoryId : '',
            })
          }
        })
        window.$('#modalAssociateLaboratory').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getLaboratorys() {
      try {
        this.laboratorys = await this.$store.dispatch('getLaboratorys', {
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async associateLaboratory() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch(
          'associateLaboratorRequestArea',
          this.areaLaboratory,
        )
        this.disableButton = false
        window.$('#modalAssociateLaboratory').modal('hide')
        this.$emit('closeModal', true)
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getRequestAreas()
    this.getLaboratorys()
  },
}
</script>
