<template>
  <div id="viewDiv"></div>
</template>

<script>
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import esriConfig from '@arcgis/core/config'
import Graphic from '@arcgis/core/Graphic'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'

esriConfig.apiKey = process.env.VUE_APP_ARCGIS_API_KEY

export default {
  name: 'ReportAreaLocation',

  props: {
    locations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      graphicsArray: [],
      fields: [
        {
          name: 'id',
          alias: 'id',
          type: 'oid',
        },
        {
          name: 'name',
          alias: 'Área',
          type: 'string',
        },
        {
          name: 'farmName',
          alias: 'Fazenda',
          type: 'string',
        },
        {
          name: 'clientName',
          alias: 'Cliente',
          type: 'string',
        },
        {
          name: 'hectare',
          alias: 'Hectare',
          type: 'string',
        },
        {
          name: 'requestId',
          alias: 'Número da Solicitação',
          type: 'string',
        },
        {
          name: 'statusName',
          alias: 'Status',
          type: 'string',
        },
        {
          name: 'latitude',
          alias: 'Latitude',
          type: 'string',
        },
        {
          name: 'longitude',
          alias: 'Longitude',
          type: 'string',
        },
        {
          name: 'city',
          alias: 'Cidade',
          type: 'string',
        },
      ],
      popupTemplate: {
        title: '{name} - {farmName}',
        content: `
          <p><b>Cliente:</b> {clientName}</b></p>
          <p><b>Área:</b> {hectare}ha</b></p>
          <p><b>Solicitação:</b> {requestId}</b></p>
          <p><b>Status:</b> {statusName}</b></p>
          <p><b>Latitude:</b> {latitude}</b></p>
          <p><b>Longitude:</b> {longitude}</b></p>
        `,
      },
      colors: [
        [255, 117, 24, 0.8],
        [227, 38, 54, 0.8],
        [153, 102, 204, 0.8],
        [255, 255, 0, 0.8],
        [0, 127, 255, 0.8],
        [25, 25, 112, 0.8],
        [128, 0, 0, 0.8],
        [0, 168, 107, 0.8],
        [255, 0, 127, 0.8],
        [0, 100, 0, 0.8],
      ],
    }
  },

  methods: {
    createMap() {
      const map = new Map({
        basemap: 'arcgis-community',
      })

      const labelClass = {
        // autocasts as new LabelClass()
        symbol: {
          type: 'text', // autocasts as new TextSymbol()
          color: 'black',
          haloColor: 'white',
          haloSize: 0.8,
          font: {
            // autocast as new Font()
            family: 'Ubuntu Mono',
            size: 12,
            weight: 'bold',
          },
        },
        labelPlacement: 'above-right',
        labelExpressionInfo: {
          expression: '$feature.name',
        },
        maxScale: 0,
        minScale: 25000000,
      }
      const itens = this.formatToGraphicObject()

      const farmLayer = new FeatureLayer({
        title: 'Localização das Áreas',
        fields: this.fields,
        labelingInfo: [labelClass],
        objectIdField: 'id',
        geometryType: 'polygon',
        spatialReference: { wkid: 4326 },
        source: itens, // adding an empty feature collection
        popupTemplate: this.popupTemplate,
      })
      map.add(farmLayer)

      const mapView = new MapView({
        map,
        center: [-44.6325565987327, -21.62399], // Longitude, latitude
        zoom: 12,
        container: 'viewDiv',
      })
    },

    formatToGraphicObject() {
      const graphics = []

      this.locations.forEach((element) => {
        if (element.inceresWkb) {
          const wkb = element.inceresWkb
            .replace('POLYGON ((', '')
            .replaceAll('), (', ',')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .split(',')

          const arrayPoly = wkb.map((item) => {
            const itemArray = item.split(' ').filter((n) => n)
            return [Number(itemArray[0]), Number(itemArray[1])]
          })

          const formatedItem = {
            geometry: {
              type: 'polygon',
              rings: arrayPoly,
            },
            attributes: element,
            symbol: {
              type: 'simple-fill',
              color: this.colors[element.clientId % 10], // Orange, opacity 80%
              outline: {
                color: [255, 255, 255],
                width: 1,
              },
            },
          }

          const newGraphic = new Graphic(formatedItem)
          graphics.push(newGraphic)
        }
      }, this)
      return graphics
    },
  },

  mounted() {
    this.createMap()
  },
}
</script>

<style scoped>
@import url('https://js.arcgis.com/4.21/esri/themes/light/main.css');
#viewDiv {
  height: calc(100vh - 220px);
  width: 100%;
}
.esri-ui-top-right.esri-ui-corner {
  max-width: 270px;
}
.esri-view-width-greater-than-large
  .esri-ui-corner
  .esri-basemap-gallery.esri-component {
  max-width: 270px;
}
</style>
