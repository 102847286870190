<template>
  <div class="row mt-2">
    <div v-if="getModuleUserByCode('2.01')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Safras"
        title="Gerenciar Safras"
        route="harvests"
        color="success"
        icon="fas fa-leaf fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('2.02')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Amostradores"
        title="Gerenciar Amostradores"
        route="samplers"
        color="info"
        icon="fas fa-user-edit fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('2.04')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Laboratórios"
        title="Gerenciar Laboratórios"
        route="laboratorys"
        color="teal"
        icon="icon-lab icon-3x"
      />
    </div>
    <div
      v-if="getModuleUserByCode('2.03.002')"
      class="col-md-4 col-sm-6 d-flex"
    >
      <card-link
        name="Nova Coleta"
        title="Cadastrar Coleta"
        route="requests/new"
        color="purple"
        icon="fas fa-motorcycle fa-3x"
      />
    </div>
    <div
      v-if="getModuleUserByCode('2.03.001') || userIsSampler"
      class="col-md-4 col-sm-6 d-flex"
    >
      <card-link
        name="Solicitações"
        title="Solicitações"
        route="requests"
        color="secondary"
        icon="fas fa-list-ol fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('2.06')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Equipamentos"
        title="Gerenciar Equipamentos"
        route="equipments"
        color="dark"
        icon="fas fa-truck-monster fa-3x"
      />
    </div>
    <div v-if="getModuleUserByCode('2.05')" class="col-md-4 col-sm-6 d-flex">
      <card-link
        name="Relatórios"
        title="Relatórios"
        route="reports"
        color="primary"
        icon="far fa-file-alt fa-3x"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardLink from '@/components/commons/CardLink.vue'

export default {
  name: 'Functionalities',

  components: {
    CardLink,
  },

  computed: {
    ...mapGetters(['getModuleUserByCode', 'userIsSampler']),
  },
}
</script>
