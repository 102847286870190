<template>
  <div
    id="modalCreateEditMaintenancePlan"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ textModal }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>
                  Nome:
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.maintenancePlan.name.$error }"
                  placeholder="Nome do Plano"
                  v-model="$v.maintenancePlan.name.$model"
                />
                <span
                  v-if="!$v.maintenancePlan.name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>
                  Unidade de controle: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.maintenancePlan.controlUnit.$error,
                  }"
                  v-model="$v.maintenancePlan.controlUnit.$model"
                >
                  <option value="">Selecione uma unidade</option>
                  <option value="Horímetro">Horímetro</option>
                  <option value="Odômetro">Odômetro</option>
                  <option value="Furos">Furos</option>
                </select>
                <span
                  v-if="!$v.maintenancePlan.controlUnit.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Periodicidade: <span class="text-danger">*</span></label>
                <input
                  type="number"
                  class="form-control"
                  :class="{ 'is-invalid': $v.maintenancePlan.frequency.$error }"
                  min="1"
                  step="1"
                  placeholder="Periodicidade"
                  v-model="$v.maintenancePlan.frequency.$model"
                />
                <span
                  v-if="!$v.maintenancePlan.frequency.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div v-if="maintenancePlanId" class="form-row">
              <div class="form-group col-sm-6">
                <p>Status: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusInativo"
                    value="0"
                    v-model="maintenancePlan.status"
                  />
                  <label class="form-check-label" for="statusInativo">
                    Inativo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="statusAtivo"
                    value="1"
                    v-model="maintenancePlan.status"
                  />
                  <label class="form-check-label" for="statusAtivo">
                    Ativo
                  </label>
                </div>
              </div>
            </div>
            <hr class="mt-0 mb-2" />
            <h5>Tarefas</h5>
            <div v-if="maintenancePlanId" class="alert alert-primary border-0">
              <span class="font-weight-semibold">Atenção:</span> Caso remover
              alguma tarefa é necessário clicar no botão "Atualizar"!
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>
                  Número de tarefas: <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="number"
                    step="1"
                    class="form-control text-center"
                    v-model="numberOfTask"
                    readonly
                  />
                  <span
                    role="button"
                    class="input-group-append"
                    @click="addTask()"
                    title="Aumentar"
                  >
                    <span
                      class="input-group-text bg-primary border-primary text-white"
                    >
                      <i class="fas fa-plus-circle" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              v-for="(task, index) in maintenancePlan.tasks"
              :key="index"
              class="form-row"
            >
              <div class="form-group col-sm-12">
                <label>
                  Nome da tarefa: <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        $v.maintenancePlan.tasks.$each[index].name.$error,
                    }"
                    placeholder="Nome da tarefa"
                    v-model="$v.maintenancePlan.tasks.$each[index].name.$model"
                  />
                  <span
                    role="button"
                    class="input-group-append"
                    @click="removeTask(index)"
                    title="Remover"
                  >
                    <span
                      class="input-group-text bg-danger border-danger text-white"
                    >
                      <i class="fas fa-trash-alt" />
                    </span>
                  </span>
                </div>
                <span
                  v-if="!$v.maintenancePlan.tasks.$each[index].name.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :title="textModal"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>
                {{ textModal }}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditMaintenancePlanModal',

  props: {
    maintenancePlanId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      numberOfTask: 1,
      maintenancePlan: {
        name: '',
        controlUnit: '',
        frequency: '',
        tasks: [
          {
            id: null,
            name: '',
          },
        ],
      },
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.maintenancePlanId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async getMaintenancePlan() {
      try {
        this.maintenancePlan = await this.$store.dispatch(
          'getMaintenancePlan',
          this.maintenancePlanId,
        )
        this.numberOfTask = this.maintenancePlan.tasks.length
        window.$('#modalCreateEditMaintenancePlan').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalCreateEditMaintenancePlan').modal('hide')
        this.$emit('closeModal', false)
      }
    },

    addTask() {
      this.numberOfTask += 1

      const task = {
        id: null,
        name: '',
      }
      this.maintenancePlan.tasks.push(task)
    },

    removeTask(index) {
      if (this.numberOfTask > 1) {
        this.numberOfTask -= 1

        const taskDelete = this.maintenancePlan.tasks[index]
        if (taskDelete.id !== null) {
          if (this.maintenancePlan.tasksDelete === undefined) {
            this.maintenancePlan.tasksDelete = []
          }
          this.maintenancePlan.tasksDelete.push(taskDelete)
        }

        this.maintenancePlan.tasks.splice(index, 1)
      } else {
        this.$root.$children[0].$refs.notification.warning(
          'O plano de manutenção deve ter ao menos uma tarefa.',
        )
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.maintenancePlanId) {
            response = await this.$store.dispatch(
              'updateMaintenancePlan',
              this.maintenancePlan,
            )
          } else {
            response = await this.$store.dispatch(
              'saveMaintenancePlan',
              this.maintenancePlan,
            )
          }
          this.disableButton = false
          window.$('#modalCreateEditMaintenancePlan').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    maintenancePlan: {
      name: {
        required,
      },
      controlUnit: {
        required,
      },
      frequency: {
        required,
      },
      tasks: {
        $each: {
          name: {
            required,
          },
        },
      },
    },
  },

  mounted() {
    if (this.maintenancePlanId) {
      this.getMaintenancePlan()
    } else {
      window.$('#modalCreateEditMaintenancePlan').modal()
    }
  },
}
</script>
