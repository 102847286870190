<template>
  <div
    id="modalDetailsMaintenancePlan"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Detalhes do Plano</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal')"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <p><strong>Nome: </strong>{{ maintenancePlan.name }}</p>
            </div>
            <div class="col-12">
              <p>
                <strong>Status: </strong>
                <span v-html="statusTranslate(maintenancePlan.status)" />
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <strong>Unidade de controle: </strong>
                {{ maintenancePlan.controlUnit }}
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <strong>Periodicidade: </strong>
                {{ maintenancePlan.frequency }}
              </p>
            </div>
            <div class="col-12">
              <p>
                <strong>Criado por:</strong>
                {{
                  createdUpdatedUser(
                    maintenancePlan.createdBy,
                    maintenancePlan.createdAt,
                  )
                }}
              </p>
            </div>
            <div class="col-12">
              <p>
                <strong>Atualizado por:</strong>
                {{
                  createdUpdatedUser(
                    maintenancePlan.updatedBy,
                    maintenancePlan.updatedAt,
                  )
                }}
              </p>
            </div>
          </div>
          <hr class="my-2" />
          <h5>Tarefas</h5>
          <ul>
            <li v-for="task in maintenancePlan.tasks" :key="task.id">
              {{ task.name }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-link"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal')"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { statusTranslate, createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'DetailsMaintenancePlanModal',

  props: {
    maintenancePlanId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      maintenancePlan: {},
      statusTranslate,
      createdUpdatedUser,
    }
  },

  methods: {
    async getMaintenancePlan() {
      try {
        this.maintenancePlan = await this.$store.dispatch(
          'getMaintenancePlan',
          this.maintenancePlanId,
        )
        window.$('#modalDetailsMaintenancePlan').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalDetailsMaintenancePlan').modal('hide')
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {
    this.getMaintenancePlan()
  },
}
</script>
