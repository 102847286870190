<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>KM</th>
        <th>Número de furos</th>
        <th>Horímetro</th>
        <th>Criado por</th>
        <th>Observações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="appointment in appointments" :key="appointment.id">
        <td>{{ appointment.km }}</td>
        <td>{{ appointment.holes }}</td>
        <td>{{ appointment.horimetro }}</td>
        <td>
          {{ createdUpdatedUser(appointment.createdBy, appointment.createdAt) }}
        </td>
        <td>{{ appointment.observation }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'ListAppointmentsEquipment',

  props: {
    appointments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListAppointmentsEquipment',
        columnDefs: [
          {
            width: 425,
            targets: [4],
          },
        ],
      },
      createdUpdatedUser,
    }
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        order: [[0, 'desc']],
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
