<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Criado por</th>
        <th>Atualizado por</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="harvest in harvests" :key="harvest.id">
        <td>{{ harvest.name }}</td>
        <td>{{ createdUpdatedUser(harvest.createdBy, harvest.createdAt) }}</td>
        <td>{{ createdUpdatedUser(harvest.updatedBy, harvest.updatedAt) }}</td>
        <td class="text-center">
          <button
            v-if="getModuleUserByCode('2.01.003')"
            type="button"
            class="btn btn-outline-warning btn-icon rounded-pill"
            title="Editar"
            @click="$emit('editHarvest', harvest.id)"
          >
            <i class="icon-pencil" />
          </button>
          <span v-else>-</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'ListHarvests',

  props: {
    harvests: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListHarvests',
        columnDefs: [
          {
            orderable: false,
            width: 100,
            targets: [3],
          },
        ],
      },
      createdUpdatedUser,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
