<template>
  <div class="content">
    <update-request-modal
      v-if="modals.showUpdateRequestModal"
      :requestId="requestId"
      @closeModal="closeModal"
    />
    <add-sampler-modal
      v-if="modals.showAddSamplerModal"
      :requestId="requestId"
      @closeModal="closeModal"
    />
    <remove-sampler-modal
      v-if="modals.showRemoveSamplerModal"
      :requestId="requestId"
      :requestSamplerId="requestSamplerId"
      :requestSamplerName="requestSamplerName"
      @closeModal="closeModal"
    />
    <insert-measure-modal
      v-if="modals.showInsertMeasureModal"
      :requestId="requestId"
      :requestAreaId="requestAreaId"
      @closeModal="closeModal"
    />
    <cancel-measure-modal
      v-if="modals.showCancelMeasureModal"
      :requestId="requestId"
      :requestAreaId="requestAreaId"
      @closeModal="closeModal"
    />
    <info-measure-modal
      v-if="modals.showInfoMeasureModal"
      :requestId="requestId"
      :requestAreaId="requestAreaId"
      @closeModal="closeModal"
    />
    <add-area-modal
      v-if="modals.showAddAreaModal"
      :requestId="requestId"
      :clientId="clientId"
      :farmId="farmId"
      @closeModal="closeModal"
    />
    <associate-laboratory-modal
      v-if="modals.showAssociateLaboratoryModal"
      :requestId="requestId"
      @closeModal="closeModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/requests" title="Voltar">
            Solicitações
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Detalhes da Solicitação
        </h5>
        <div class="header-elements">
          <span :class="`btn btn-${request.statusClass}`">
            {{ request.statusName }}
          </span>
        </div>
      </div>
      <div class="card-body">
        <h4 class="font-weight-light">Dados da Solicitação:</h4>
        <div class="row">
          <div class="col-md-6 col-12">
            <p><strong>Safra:</strong> {{ request.harvestName }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p>
              <strong>Data Esperada da Coleta:</strong>
              {{ dateTimeSqlToDateBR(request.collectionDate) }}
            </p>
          </div>
          <div class="col-md-6 col-12">
            <p><strong>Cliente:</strong> {{ request.clientName }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p><strong>Fazenda:</strong> {{ request.farmName }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p><strong>Nome do Contato:</strong> {{ request.contactName }}</p>
          </div>
          <div class="col-md-6 col-12">
            <p>
              <strong>Telefone do Contato:</strong> {{ request.contactPhone }}
            </p>
          </div>
          <div class="col-md-6 col-12">
            <p>
              <strong>Criado por:</strong>
              {{ createdUpdatedUser(request.createdBy, request.createdAt) }}
            </p>
          </div>
          <div class="col-md-6 col-12">
            <p>
              <strong>Atualizado por:</strong>
              {{ createdUpdatedUser(request.updatedBy, request.updatedAt) }}
            </p>
          </div>
          <div class="col-12">
            <p>
              <strong>Observações: </strong>
              <span v-if="request.observation.includes('http')">
                <a
                  :href="request.observation"
                  :title="request.observation"
                  target="_blank"
                >
                  Abrir link
                </a>
              </span>
              <span v-else>{{ request.observation }}</span>
            </p>
          </div>
          <div
            v-if="request.status == 100 && getModuleUserByCode('2.03.003')"
            class="col-12 text-right"
          >
            <button
              class="btn btn-outline-warning"
              title="Atualizar Solicitação"
              @click="updateRequest()"
            >
              <i class="far fa-edit mr-2" />Atualizar Solicitação
            </button>
          </div>
        </div>
        <hr class="my-2" />
        <h4 class="font-weight-light">Amostradores:</h4>
        <ul>
          <li v-for="sampler in request.samplers" :key="sampler.id">
            {{ sampler.userName }}
            <span
              v-if="request.status == 100 && getModuleUserByCode('2.03.003')"
              role="button"
              class="text-danger"
              title="Remover amostrador da solicitação"
              @click="removeSampler(sampler.id, sampler.userName)"
            >
              (Remover)
            </span>
          </li>
        </ul>
        <div
          v-if="request.status == 100 && getModuleUserByCode('2.03.003')"
          class="row"
        >
          <div class="col-12 text-right">
            <button
              class="btn btn-outline-primary"
              title="Adicionar Amostrador"
              @click="addSampler()"
            >
              <i class="fas fa-plus mr-2" />Adicionar Amostrador
            </button>
          </div>
        </div>
        <hr class="my-2" />
        <h4 class="font-weight-light">Talhões:</h4>
        <div class="row">
          <div class="col-12">
            <div id="no-more-tables">
              <table class="table table-striped">
                <thead>
                  <tr class="text-center">
                    <th>Talhão</th>
                    <th>Status</th>
                    <th>Área Solicitada</th>
                    <th>Área Medida</th>
                    <th>Nr. de Grid</th>
                    <th>Localização</th>
                    <th>Laboratório</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="area in request.areas"
                    :key="area.id"
                    class="text-center"
                  >
                    <td data-title="Talhão">{{ area.areaName }}</td>
                    <td data-title="Status">
                      <span :class="`badge bg-${area.statusClass}`">
                        {{ area.statusName }}
                      </span>
                    </td>
                    <td data-title="Área Solicitada">{{ area.hectare }} ha</td>
                    <td data-title="Área Medida">
                      {{ area.totalMeasured }} ha
                    </td>
                    <td data-title="Nr. de Grid">
                      {{ area.numberGrid ? area.numberGrid : '-' }}
                    </td>
                    <td data-title="Observações">
                      <span v-if="area.observation.includes('http')">
                        <a
                          :href="area.observation"
                          :title="area.observation"
                          target="_blank"
                        >
                          Abrir link
                        </a>
                      </span>
                      <span v-else>
                        {{ area.observation ? area.observation : '-' }}
                      </span>
                    </td>
                    <td data-title="Laboratório">
                      {{ area.laboratoryName ? area.laboratoryName : '-' }}
                    </td>
                    <td data-title="Ações">
                      <button
                        v-if="
                          Number(area.hectare) > Number(area.totalMeasured) &&
                          area.status != 290 &&
                          (getModuleUserByCode('2.03.003') || userIsSampler)
                        "
                        class="btn btn-outline-success btn-icon rounded-pill mr-2"
                        title="Realizar Medição"
                        @click="insertMeasure(area.id)"
                      >
                        <i class="fas fa-upload" />
                      </button>
                      <button
                        v-if="area.totalMeasured > 0 || area.status == 290"
                        class="btn btn-outline-info btn-icon rounded-pill mr-2"
                        title="Ver Medições"
                        @click="infoMeasure(area.id)"
                      >
                        <i class="fas fa-info" />
                      </button>
                      <button
                        v-if="
                          area.totalMeasured == 0 &&
                          area.status != 290 &&
                          (getModuleUserByCode('2.03.003') || userIsSampler)
                        "
                        class="btn btn-outline-danger btn-icon rounded-pill"
                        title="Cancelar Medição"
                        @click="cancelMeasure(area.id)"
                      >
                        <i class="fas fa-ban" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="request.status == 100 && user.id" class="row mt-4">
          <div class="col-12 text-right">
            <button
              class="btn btn-outline-primary"
              title="Adicionar Talhão"
              @click="addArea()"
            >
              <i class="fas fa-plus mr-2" />Adicionar Talhão
            </button>
          </div>
        </div>

        <div
          v-if="
            (request.status == 110 || request.status == 111) &&
            request.requesterId == user.id
          "
          class="row mt-4"
        >
          <div class="col-12 text-right">
            <button
              class="btn btn-outline-primary"
              title="Associar Laboratório em cada Talhão"
              @click="associateLaboratory()"
            >
              <i class="icon-lab mr-2" />Associar Laboratório
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateTimeSqlToDateBR } from '@/utils/date'
import { createdUpdatedUser } from '@/utils/strings'

import LoadingData from '@/components/commons/LoadingData.vue'
import AddAreaModal from '@/components/precision/request/AddAreaModal.vue'
import AddSamplerModal from '@/components/precision/request/AddSamplerModal.vue'
import AssociateLaboratoryModal from '@/components/precision/request/AssociateLaboratoryModal.vue'
import CancelMeasureModal from '@/components/precision/request/CancelMeasureModal.vue'
import InsertMeasureModal from '@/components/precision/request/InsertMeasureModal.vue'
import InfoMeasureModal from '@/components/precision/request/InfoMeasureModal.vue'
import RemoveSamplerModal from '@/components/precision/request/RemoveSamplerModal.vue'
import UpdateRequestModal from '@/components/precision/request/UpdateRequestModal.vue'

export default {
  name: 'PrecisionRequestDetails',

  components: {
    LoadingData,
    UpdateRequestModal,
    AddSamplerModal,
    RemoveSamplerModal,
    InsertMeasureModal,
    CancelMeasureModal,
    InfoMeasureModal,
    AddAreaModal,
    AssociateLaboratoryModal,
  },

  data() {
    return {
      loadData: true,
      requestId: this.$route.params.requestId,
      request: null,
      clientId: null,
      farmId: null,
      requestAreaId: null,
      requestSamplerId: null,
      requestSamplerName: null,
      modals: {
        showUpdateRequestModal: false,
        showAddSamplerModal: false,
        showRemoveSamplerModal: false,
        showInsertMeasureModal: false,
        showCancelMeasureModal: false,
        showInfoMeasureModal: false,
        showAddAreaModal: false,
        showAssociateLaboratoryModal: false,
      },
      dateTimeSqlToDateBR,
      createdUpdatedUser,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode', 'user', 'userIsSampler']),
  },

  methods: {
    async getRequest() {
      try {
        this.request = await this.$store.dispatch('getRequest', this.requestId)
        this.clientId = this.request.clientId
        this.farmId = this.request.farmId
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    updateRequest() {
      this.modals.showUpdateRequestModal = true
    },

    addSampler() {
      this.modals.showAddSamplerModal = true
    },

    removeSampler(requestSamplerId, requestSamplerName) {
      this.requestSamplerId = requestSamplerId
      this.requestSamplerName = requestSamplerName
      this.modals.showRemoveSamplerModal = true
    },

    insertMeasure(requestAreaId) {
      this.requestAreaId = requestAreaId
      this.modals.showInsertMeasureModal = true
    },

    cancelMeasure(requestAreaId) {
      this.requestAreaId = requestAreaId
      this.modals.showCancelMeasureModal = true
    },

    infoMeasure(requestAreaId) {
      this.requestAreaId = requestAreaId
      this.modals.showInfoMeasureModal = true
    },

    addArea() {
      this.modals.showAddAreaModal = true
    },

    associateLaboratory() {
      this.modals.showAssociateLaboratoryModal = true
    },

    closeModal(loadData) {
      if (loadData) {
        this.getRequest()
      }
      this.modals = {
        showUpdateRequestModal: false,
        showAddSamplerModal: false,
        showRemoveSamplerModal: false,
        showInsertMeasureModal: false,
        showCancelMeasureModal: false,
        showInfoMeasureModal: false,
        showAddAreaModal: false,
        showAssociateLaboratoryModal: false,
      }
    },
  },

  mounted() {
    this.getRequest()
  },
}
</script>

<style scoped>
#no-more-tables table th:last-child {
  width: 10rem;
}

@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }

  #no-more-tables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  #no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  /*
	Label the data
	*/
  #no-more-tables td:before {
    content: attr(data-title);
  }
}
</style>
