<template>
  <div
    id="modalInfoMeasure"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Medições</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <p><strong>Talhão: </strong>{{ area.areaName }}</p>
            </div>
            <div class="col-12 col-md-6">
              <p>
                <strong>Status: </strong>
                <span :class="`badge bg-${area.statusClass}`">
                  {{ area.statusName }}
                </span>
              </p>
            </div>
            <div class="col-12 col-md-6">
              <p><strong>Total da Área: </strong>{{ area.hectare }} ha</p>
            </div>
            <div class="col-12 col-md-6">
              <p><strong>Total Medido: </strong>{{ area.totalMeasured }} ha</p>
            </div>
            <div class="col-12 col-md-6">
              <p><strong>Número de Grid: </strong>{{ area.numberGrid }}</p>
            </div>
            <div v-if="area.observation" class="col-12">
              <p>
                <strong>Observações: </strong>
                <span v-if="area.observation.includes('http')">
                  <a
                    :href="area.observation"
                    :title="area.observation"
                    target="_blank"
                  >
                    Abrir link
                  </a>
                </span>
                <span v-else>{{ area.observation }} </span>
              </p>
            </div>
            <div v-if="area.laboratoryName" class="col-12">
              <p>
                <strong>Laboratório: </strong>
                {{ area.laboratoryName }} ({{ area.laboratoryType }})
              </p>
            </div>
            <div v-if="area.cancellationReason" class="col-12">
              <p>
                <strong>Motivo do Cancelamento: </strong>
                {{ area.cancellationReason }}
              </p>
            </div>
            <div class="col-md-6 col-12">
              <p>
                <strong>Criado: </strong>
                {{ createdUpdatedUser(area.createdBy, area.createdAt) }}
              </p>
            </div>
            <div class="col-md-6 col-12">
              <p>
                <strong>Atualizado: </strong>
                {{ createdUpdatedUser(area.updatedBy, area.updatedAt) }}
              </p>
            </div>
          </div>
          <hr v-if="area.measures && area.measures.length > 0" class="my-2" />
          <div v-if="area.measures && area.measures.length > 0" class="row">
            <div class="col-12">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="text-center">Medição</th>
                    <th class="text-center">Observação</th>
                    <th class="text-center">Registrado Por</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="measure in area.measures" :key="measure.id">
                    <td class="text-center">{{ measure.hectare }} ha</td>
                    <td class="text-center">{{ measure.observation }}</td>
                    <td class="text-center">
                      {{
                        createdUpdatedUser(measure.createdBy, measure.createdAt)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-link"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'InfoMeasureModal',

  props: {
    requestId: {
      type: String,
      required: true,
    },
    requestAreaId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      area: {},
      createdUpdatedUser,
    }
  },

  methods: {
    async getRequestArea() {
      try {
        this.area = await this.$store.dispatch('getRequestArea', {
          requestId: this.requestId,
          requestAreaId: this.requestAreaId,
        })
        window.$('#modalInfoMeasure').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {
    this.getRequestArea()
  },
}
</script>
