<template>
  <div class="content">
    <create-edit-equipment-modal
      v-if="showCreateEditEquipmentModal"
      :equipmentId="equipmentIdSelected"
      @closeModal="closeCreateEditEquipmentModal"
    />
    <loading-data v-if="loadData" />
    <div v-else class="card">
      <div class="card-header bg-transparent header-elements-inline">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/equipments" title="Voltar">
            Equipamentos
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Listagem
        </h5>
        <div v-if="getModuleUserByCode('2.06.002')" class="header-elements">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Equipamento"
            @click="createEditEquipment()"
          >
            Novo Equipamento
          </button>
        </div>
      </div>
      <list-equipments
        :equipments="equipments"
        @editEquipment="createEditEquipment"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingData from '@/components/commons/LoadingData.vue'
import CreateEditEquipmentModal from '@/components/precision/equipments/equipments/CreateEditEquipmentModal.vue'
import ListEquipments from '@/components/precision/equipments/equipments/ListEquipments.vue'

export default {
  name: 'PrecisionEquipments',

  components: {
    LoadingData,
    CreateEditEquipmentModal,
    ListEquipments,
  },

  data() {
    return {
      loadData: true,
      showCreateEditEquipmentModal: false,
      equipments: [],
      equipmentIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getEquipments() {
      try {
        this.equipments = await this.$store.dispatch('getEquipments')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    createEditEquipment(equipmentId) {
      this.equipmentIdSelected = equipmentId || null
      this.showCreateEditEquipmentModal = true
    },

    closeCreateEditEquipmentModal(loadListEquipments) {
      if (loadListEquipments) {
        this.loadData = true
        this.getEquipments()
      }
      this.showCreateEditEquipmentModal = false
    },
  },

  mounted() {
    this.getEquipments()
  },
}
</script>
