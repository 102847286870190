<template>
  <div class="card">
    <div class="card-header">
      <h6 class="font-weight-light">Status das Áreas</h6>
    </div>
    <div class="card-body">
      <v-chart class="chart" :option="optionReder" />
      <strong>Legenda:</strong>
      <p>
        <small>
          <em>Em Andamento</em>: Considera todas as áreas selecionadas em todas
          as solicitações e que atualmente estão com status "Aguardando Coleta".
        </small>
      </p>
      <p>
        <small>
          <em>Medidas</em>: Considera todas as áreas selecionadas em todas as
          solicitações e que atualmente estão com status "Coletada Parcialmente"
          e "Coleta". Para as áreas que estão "Coletada Parcialmente" é
          considerado apenas o valor parcial já informado.
        </small>
      </p>
      <p>
        <small>
          <em>Disponíveis</em>: Considera todas as áreas ativas disponíveis
          menos as áreas "Em Endamento" e "Medidas".
        </small>
      </p>
    </div>
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  GridComponent,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
])

export default {
  name: 'StatusChart',

  props: {
    option: {
      type: Array,
      required: true,
    },
  },

  components: {
    VChart,
  },

  data() {
    return {
      optionReder: {},
    }
  },

  methods: {
    generateOptionToChart() {
      this.optionReder = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          top: '5%',
          left: 'center',
        },
        series: [
          {
            name: 'Área',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.option,
          },
        ],
      }
    },
  },

  mounted() {
    this.generateOptionToChart()
  },
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
