<template>
  <div class="d-flex flex-row align-items-center">
    <img
      src="@/assets/img/modules/agricultura-precisao-minimalista.png"
      alt="Agricultura de Precisão"
      width="60"
    />
    <h1 class="ml-3 my-2 font-weight-light">Módulo de Precisão</h1>
  </div>
</template>

<script>
export default {
  name: 'HeaderPage',
}
</script>
