<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Nome</th>
        <th>Tipo</th>
        <th>Criado por</th>
        <th>Atualizado por</th>
        <th class="text-center">Status</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="laboratory in laboratorys" :key="laboratory.id">
        <td>{{ laboratory.name }}</td>
        <td>{{ laboratory.type }}</td>
        <td>
          {{ createdUpdatedUser(laboratory.createdBy, laboratory.createdAt) }}
        </td>
        <td>
          {{ createdUpdatedUser(laboratory.updatedBy, laboratory.updatedAt) }}
        </td>
        <td class="text-center" v-html="statusTranslate(laboratory.status)" />
        <td class="text-center">
          <button
            v-if="getModuleUserByCode('2.04.003')"
            type="button"
            class="btn btn-outline-warning btn-icon rounded-pill"
            title="Editar"
            @click="$emit('editLaboratory', laboratory.id)"
          >
            <i class="icon-pencil" />
          </button>
          <span v-else>-</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { createdUpdatedUser, statusTranslate } from '@/utils/strings'

export default {
  name: 'ListLaboratorys',

  props: {
    laboratorys: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListLaboratorys',
        columnDefs: [
          {
            orderable: false,
            width: 100,
            targets: [5],
          },
        ],
      },
      createdUpdatedUser,
      statusTranslate,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
