<template>
  <div
    id="modalDeleteSampler"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Remover Amostrador</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="deleteSampler">
          <div class="modal-body">
            <p>
              Tem certeza que deseja remover o colaborador "{{ sampler.name }}"
              da função de amostrador?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link text-danger"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              title="Remover Colaborador"
              :disabled="disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Remover</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteSamplerModal',

  props: {
    samplerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      sampler: {
        id: '',
        userId: '',
        userName: '',
      },
      disableButton: false,
    }
  },

  methods: {
    async getSampler() {
      try {
        this.sampler = await this.$store.dispatch('getSampler', this.samplerId)
        window.$('#modalDeleteSampler').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async deleteSampler() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch(
          'deleteSampler',
          this.sampler.id,
        )
        this.disableButton = false
        window.$('#modalDeleteSampler').modal('hide')
        this.$emit('closeModal', true)
        this.$root.$children[0].$refs.notification.success(response.message)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getSampler()
  },
}
</script>
