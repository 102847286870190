<template>
  <div class="alert alert-warning alert-styled-left mt-2 mb-1">
    <p>Realizar o apontamento dos seguintes equipamentos:</p>
    <ul class="mb-0 pl-3">
      <li class="mb-2" v-for="equipment in equipments" :key="equipment.id">
        Número: {{ equipment.name }} | Ano: {{ equipment.year }} | Cor:
        {{ equipment.color }}
        <router-link
          class="badge badge-flat border-primary text-primary"
          :to="`equipments/list/${equipment.id}`"
          title="Acessar Equipamento"
        >
          Acessar
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AlertAppointmentEquipment',

  props: {
    equipments: {
      type: Array,
      required: true,
    },
  },
}
</script>
