var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataReport.report.length > 0)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('report-buttons',{attrs:{"nameExcel":'Relatorio_Status_dos_Talhoes',"worksheetExcel":_vm.title,"fieldsExcel":_vm.fieldsExcel,"dataExcel":_vm.dataReport.report,"divElementPrint":'printReport'}}),_c('div',{attrs:{"id":"printReport"}},[_c('report-header',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered dataTable table-report"},[_c('thead',[_c('tr',[_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'farmName' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'farmName' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('farmName')}}},[_vm._v(" Fazenda ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'name' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'name' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('name')}}},[_vm._v(" Talhão ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'areaHectare' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'areaHectare' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('areaHectare')}}},[_vm._v(" Área (ha) ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'areaRequested' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'areaRequested' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('areaRequested')}}},[_vm._v(" Área Solicitada (ha) ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'areaMeasure' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'areaMeasure' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('areaMeasure')}}},[_vm._v(" Área Coletada (ha) ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'lastDate' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'lastDate' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('lastDate')}}},[_vm._v(" Data da Coleta ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumn === 'status' &&
                        _vm.dataReport.sortType === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumn === 'status' &&
                        _vm.dataReport.sortType === 'desc',
                    },on:{"click":function($event){return _vm.sortColumn('status')}}},[_vm._v(" Status ")])])]),_c('tbody',[_vm._l((_vm.dataReport.report),function(report,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(report.farmName))]),_c('td',[_vm._v(_vm._s(report.name))]),_c('td',[_vm._v(_vm._s(report.areaHectareToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.areaRequestedToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.areaMeasureToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.lastDateToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.status !== null ? report.status : '-'))])])}),_c('tr',[_vm._m(0),_c('td',[_vm._v("-")]),_c('td',[_vm._v(_vm._s(_vm.dataReport.totals.areaHectare))]),_c('td',[_vm._v(_vm._s(_vm.dataReport.totals.areaRequested))]),_c('td',[_vm._v(" "+_vm._s(_vm.dataReport.totals.areaMeasure)+" "),_c('span',{attrs:{"title":"Área Coletada (ha) / Área (ha)"}},[_vm._v(" ("+_vm._s(_vm.dataReport.totals.areaMeasurePercent)+"%) ")])]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")])])],2)])])],1)],1)])]):_c('report-not-found')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Total")])])}]

export { render, staticRenderFns }