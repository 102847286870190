<template>
  <div
    id="modalAddMaintenancePlanEquipment"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Associar Plano de Manutenção ao Equipamento
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>
                  Plano de Manutenção:
                  <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control"
                  :class="{
                    'is-invalid':
                      $v.equipmentMaintenancePlan.maintenancePlanId.$error,
                  }"
                  v-model="$v.equipmentMaintenancePlan.maintenancePlanId.$model"
                >
                  <option value="">Selecione o Plano</option>
                  <option
                    v-for="maintenancePlan in maintenancePlans"
                    :key="maintenancePlan.id"
                    :value="maintenancePlan.id"
                  >
                    {{ maintenancePlan.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.equipmentMaintenancePlan.maintenancePlanId.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div
              v-if="Object.keys(maintenancePlanSelected).length !== 0"
              class="alert alert-primary"
            >
              <h5>Detalhes do Plano Selecionado</h5>
              <div class="row">
                <div class="col-12">
                  <p>
                    <strong>Nome:</strong> {{ maintenancePlanSelected.name }}
                  </p>
                </div>
                <div class="col-12 col-md-6">
                  <p>
                    <strong>Unidade de controle:</strong>
                    {{ maintenancePlanSelected.controlUnit }}
                  </p>
                </div>
                <div class="col-12 col-md-6">
                  <p>
                    <strong>Periodicidade:</strong>
                    {{ maintenancePlanSelected.frequency }}
                  </p>
                </div>
              </div>
              <hr class="my-2" />
              <h5>Tarefas</h5>
              <ul>
                <li
                  v-for="task in maintenancePlanSelected.tasks"
                  :key="task.id"
                >
                  {{ task.name }}
                </li>
              </ul>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>
                  Data da Última Manutenção:
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      $v.equipmentMaintenancePlan.dateLastMaintenance.$error,
                  }"
                  placeholder="Data da Última Manutenção"
                  v-model="
                    $v.equipmentMaintenancePlan.dateLastMaintenance.$model
                  "
                />
                <span
                  v-if="
                    !$v.equipmentMaintenancePlan.dateLastMaintenance.required
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>
                  Última Manutenção:
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      $v.equipmentMaintenancePlan.valueLastMaintenance.$error,
                  }"
                  min="0"
                  step="1"
                  placeholder="Última Manutenção"
                  v-model.number="
                    $v.equipmentMaintenancePlan.valueLastMaintenance.$model
                  "
                />
                <span
                  v-if="
                    !$v.equipmentMaintenancePlan.valueLastMaintenance.required
                  "
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Cadastrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddMaintenancePlanEquipmentModal',

  props: {
    equipmentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      maintenancePlans: [],
      equipmentMaintenancePlan: {
        maintenancePlanId: '',
        dateLastMaintenance: '',
        valueLastMaintenance: '',
      },
      maintenancePlanSelected: {},
      disableButton: false,
    }
  },

  watch: {
    'equipmentMaintenancePlan.maintenancePlanId': {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.maintenancePlanSelected = this.maintenancePlans.find(
            (maintenancePlan) => maintenancePlan.id === newVal,
          )
        } else {
          this.maintenancePlanSelected = {}
        }
      },
    },
  },

  methods: {
    async getMaintenancePlans() {
      try {
        this.maintenancePlans = await this.$store.dispatch(
          'getMaintenancePlans',
          this.equipmentId,
        )
        window.$('#modalAddMaintenancePlanEquipment').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'addMaintenancePlanEquipment',
            {
              equipmentId: this.equipmentId,
              equipmentMaintenancePlan: this.equipmentMaintenancePlan,
            },
          )
          this.disableButton = false
          window.$('#modalAddMaintenancePlanEquipment').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    equipmentMaintenancePlan: {
      maintenancePlanId: {
        required,
      },
      dateLastMaintenance: {
        required,
      },
      valueLastMaintenance: {
        required,
      },
    },
  },

  mounted() {
    this.getMaintenancePlans()
  },
}
</script>

<style scoped>
ul {
  margin-bottom: 0;
  padding-left: 15px;
}
</style>
