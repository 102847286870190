<template>
  <div>
    <div v-if="dataReport.byLaboratory.length > 0">
      <div class="card">
        <div class="card-body">
          <report-buttons
            :nameExcel="'Relatorio_Destino_das_Amostras_Resumo'"
            :worksheetExcel="titleByLaboratory"
            :fieldsExcel="fieldsByLaboratoryExcel"
            :dataExcel="dataReport.byLaboratory"
            :divElementPrint="'printReportByLaboratory'"
          />
          <div id="printReportByLaboratory">
            <report-header :title="titleByLaboratory" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnByLaboratory === 'laboratory' &&
                          dataReport.sortTypeByLaboratory === 'asc',
                        sorting_desc:
                          dataReport.sortColumnByLaboratory === 'laboratory' &&
                          dataReport.sortTypeByLaboratory === 'desc',
                      }"
                      @click="sortColumnByLaboratory('laboratory')"
                    >
                      Laboratório
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnByLaboratory === 'hectare' &&
                          dataReport.sortTypeByLaboratory === 'asc',
                        sorting_desc:
                          dataReport.sortColumnByLaboratory === 'hectare' &&
                          dataReport.sortTypeByLaboratory === 'desc',
                      }"
                      @click="sortColumnByLaboratory('hectare')"
                    >
                      Coletado
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(report, index) in dataReport.byLaboratory"
                    :key="index"
                  >
                    <td>{{ report.laboratory }}</td>
                    <td>{{ report.hectareToCurrencyBr }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>{{ dataReport.totals.byLaboratory }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <report-buttons
            :nameExcel="'Relatorio_Destino_das_Amostras_Detalhe'"
            :worksheetExcel="titleDetails"
            :fieldsExcel="fieldsDetailsExcel"
            :dataExcel="dataReport.details"
            :divElementPrint="'printReportDetails'"
          />
          <div id="printReportDetails">
            <report-header :title="titleDetails" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'laboratory' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'laboratory' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('laboratory')"
                    >
                      Laboratório
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'client' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'client' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('client')"
                    >
                      Cliente
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'farm' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'farm' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('farm')"
                    >
                      Fazenda
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'area' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'area' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('area')"
                    >
                      Talhão
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'hectare' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'hectare' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('hectare')"
                    >
                      Hectare
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumnDetails === 'date' &&
                          dataReport.sortTypeDetails === 'asc',
                        sorting_desc:
                          dataReport.sortColumnDetails === 'date' &&
                          dataReport.sortTypeDetails === 'desc',
                      }"
                      @click="sortColumnDetails('date')"
                    >
                      Data da Coleta
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(report, index) in dataReport.details"
                    :key="index"
                  >
                    <td>{{ report.laboratory }}</td>
                    <td>{{ report.client }}</td>
                    <td>{{ report.farm }}</td>
                    <td>{{ report.area }}</td>
                    <td>{{ report.hectareToCurrencyBr }}</td>
                    <td>{{ report.dateToCurrencyBr }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ dataReport.totals.details }}</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <report-not-found v-else />
  </div>
</template>

<script>
import sortArray from 'sort-array'
import ReportButtons from '@/components/precision/report/ReportButtons.vue'
import ReportHeader from '@/components/precision/report/ReportHeader.vue'
import ReportNotFound from '@/components/precision/report/ReportNotFound.vue'

export default {
  name: 'ReportDestinationOfSamples',

  props: {
    dataReport: {
      type: Object,
      required: true,
    },
  },

  components: {
    ReportHeader,
    ReportButtons,
    ReportNotFound,
  },

  data() {
    return {
      titleByLaboratory: 'Destino das Amostras (Resumo)',
      titleDetails: 'Destino das Amostras (Detalhe)',
      fieldsByLaboratoryExcel: {
        Laboratório: 'laboratory',
        Coletado: 'hectare',
      },
      fieldsDetailsExcel: {
        'Laboratório': 'laboratory',
        'Cliente': 'client',
        'Fazenda': 'farm',
        'Talhão': 'area',
        'Hectare': 'hectare',
        'Data Coleta': 'dateToCurrencyBr',
      },
    }
  },

  methods: {
    sortColumnByLaboratory(column) {
      this.dataReport.sortColumnByLaboratory = column
      this.dataReport.sortTypeByLaboratory =
        this.dataReport.sortTypeByLaboratory === '' ||
        this.dataReport.sortTypeByLaboratory === 'desc'
          ? 'asc'
          : 'desc'
      sortArray(this.dataReport.byLaboratory, {
        by: this.dataReport.sortColumnByLaboratory,
        order: this.dataReport.sortTypeByLaboratory,
      })
    },

    sortColumnDetails(column) {
      this.dataReport.sortColumnDetails = column
      this.dataReport.sortTypeDetails =
        this.dataReport.sortTypeDetails === '' ||
        this.dataReport.sortTypeDetails === 'desc'
          ? 'asc'
          : 'desc'
      sortArray(this.dataReport.details, {
        by: this.dataReport.sortColumnDetails,
        order: this.dataReport.sortTypeDetails,
      })
    },
  },
}
</script>
