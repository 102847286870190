<template>
  <div
    id="modalAddArea"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Adicionar Talhão</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="addArea">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-12">
                <label>Talhão: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.requestArea.area.$error }"
                  v-model="$v.requestArea.area.$model"
                >
                  <option value="">Selecione um talhão</option>
                  <option
                    v-for="area in areas"
                    :key="area.id"
                    :class="{ 'text-muted': area.statusCode }"
                    :title="`${
                      area.requestId
                        ? `A área solicitanda faz parte da solicitação Nº ${area.requestId} e seu status é ${area.statusName}`
                        : ''
                    }`"
                    :value="{ areaId: area.id, hectare: area.hectare }"
                  >
                    {{ area.name }} - (Área: {{ area.hectare }})
                  </option>
                </select>
                <span
                  v-if="!$v.requestArea.area.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-12">
                <label>Número de Grids:</label>
                <input
                  type="number"
                  min="1"
                  step="1"
                  class="form-control"
                  placeholder="Número de Grids"
                  v-model="requestArea.numberGrid"
                />
              </div>
              <div class="form-group col-12">
                <label>Localização:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Localização"
                  v-model="requestArea.observation"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Adicionar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Adicionar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddAreaModal',

  props: {
    requestId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    farmId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      areas: null,
      requestArea: {
        requestId: this.requestId,
        area: '',
        numberGrid: '',
        observation: '',
      },
      disableButton: false,
    }
  },

  methods: {
    async getAreas() {
      try {
        this.areas = await this.$store.dispatch('getAreas', {
          clientId: this.clientId,
          farmId: this.farmId,
          status: 1,
          requestId: this.requestId,
        })
        window.$('#modalAddArea').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalAddArea').modal('hide')
        this.$emit('closeModal', false)
      }
    },

    async addArea() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'addAreaRequest',
            this.requestArea,
          )
          this.disableButton = false
          window.$('#modalAddArea').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    requestArea: {
      area: {
        required,
      },
    },
  },

  mounted() {
    this.getAreas()
  },
}
</script>
