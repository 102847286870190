<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Nova Solicitação
        </h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="save">
          <div class="form-row">
            <div class="form-group col-12 col-sm-4">
              <label>Safra: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.request.harvestId.$error }"
                v-model="$v.request.harvestId.$model"
              >
                <option value="">Selecione a Safra</option>
                <option
                  v-for="harvest in harvests"
                  :key="harvest.id"
                  :value="harvest.id"
                  :title="harvest.name"
                >
                  {{ harvest.name }}
                </option>
              </select>
              <span
                v-if="!$v.request.harvestId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Cliente: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.request.clientId.$error }"
                v-model="$v.request.clientId.$model"
              >
                <option value="">Selecione um Cliente</option>
                <option
                  v-for="client in clients"
                  :key="client.id"
                  :value="client.id"
                  :title="client.name"
                >
                  {{ client.name }}
                </option>
              </select>
              <span
                v-if="!$v.request.clientId.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Fazenda: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.request.farmId.$error }"
                v-model="$v.request.farmId.$model"
              >
                <option value="">Selecione uma Fazenda</option>
                <option
                  v-for="farm in farms"
                  :key="farm.id"
                  :value="farm.id"
                  :title="farm.name"
                >
                  {{ farm.name }}
                </option>
              </select>
              <span v-if="!$v.request.farmId.required" class="invalid-feedback">
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12 mb-0">
              <label>Talhões: <span class="text-danger">*</span></label>
              <div v-if="areas.length > 0">
                <div
                  v-for="(area, index) in areas"
                  :key="area.id"
                  class="row d-flex align-items-center mb-3"
                >
                  <div class="col-sm-4">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`area_${area.id}`"
                        :value="area"
                        v-model="request.areas"
                      />
                      <label
                        :title="`${
                          area.requestId
                            ? `A área solicitanda faz parte da solicitação Nº ${area.requestId} e seu status é ${area.statusName}`
                            : ''
                        }`"
                        class="custom-control-label mb-2"
                        :class="{ 'text-muted': area.statusCode }"
                        :for="`area_${area.id}`"
                      >
                        {{ area.name }} - (Área: {{ area.hectare }})
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <input
                      type="number"
                      min="1"
                      step="1"
                      class="form-control"
                      placeholder="Número de Grid"
                      v-model="areas[index].numberGrid"
                    />
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Localização"
                      v-model="areas[index].observation"
                    />
                  </div>
                </div>
                <p class="mb-0">
                  <em>Obs: Apenas talhões selecionados serão salvos</em>
                </p>
              </div>
              <div v-else>
                <p class="mb-0"><em>Selecione uma fazenda primeiro.</em></p>
              </div>
              <span v-if="!$v.request.areas.required" class="invalid-feedback">
                O campo é obrigatório
              </span>
            </div>
          </div>
          <hr />
          <div class="form-row">
            <div class="form-group col-12 mb-0">
              <label>Amostradores: <span class="text-danger">*</span></label>
              <div
                class="row mb-2"
                v-for="sampler in samplers"
                :key="sampler.id"
              >
                <div class="col-12">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`sampler_${sampler.userId}`"
                      :value="sampler.userId"
                      v-model="request.samplers"
                    />
                    <label
                      class="custom-control-label"
                      :for="`sampler_${sampler.userId}`"
                    >
                      {{ sampler.name }}
                    </label>
                  </div>
                </div>
              </div>
              <span
                v-if="!$v.request.samplers.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
          </div>
          <hr />
          <div class="form-row">
            <div class="form-group col-12 col-sm-4">
              <label>Pessoa de Contato:</label>
              <input
                type="text"
                class="form-control"
                placeholder="Nome da Pessoa"
                v-model="request.contactName"
              />
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>Telefone de Contato:</label>
              <the-mask
                type="text"
                class="form-control"
                :class="{ 'is-invalid': $v.request.contactPhone.$error }"
                placeholder="(00) 98888-8888"
                v-model="$v.request.contactPhone.$model"
                :mask="['(##) ####-####', '(##) #####-####']"
                :masked="true"
              />
              <span
                v-if="!$v.request.contactPhone.minLength"
                class="invalid-feedback"
              >
                Formato de telefone inválido <br />
                Ex: (00) 98888-8888 / (00) 8888-8888
              </span>
            </div>
            <div class="form-group col-12 col-sm-4">
              <label>
                Data Liberada Para Coleta:
                <span class="text-danger">*</span>
              </label>
              <input
                type="date"
                class="form-control"
                :class="{ 'is-invalid': $v.request.collectionDate.$error }"
                v-model="$v.request.collectionDate.$model"
              />
              <span
                v-if="!$v.request.collectionDate.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-group col-12">
              <label>Observações da Solicitação:</label>
              <textarea
                class="form-control"
                placeholder="Observações"
                v-model="request.observation"
              />
            </div>
          </div>
          <div class="text-right">
            <router-link
              to="/precision/home"
              class="btn btn-link"
              title="Cancelar"
            >
              Cancelar
            </router-link>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar Solicitação"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Cadastrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import PATHS from '@/router/routesMap'

export default {
  name: 'PrecisionRequestNew',

  components: {
    TheMask,
  },

  data() {
    return {
      harvests: [],
      clients: [],
      farms: [],
      areas: [],
      samplers: [],
      request: {
        harvestId: '',
        clientId: '',
        farmId: '',
        contactName: '',
        contactPhone: '',
        collectionDate: '',
        observation: '',
        areas: [],
        samplers: [],
      },
      disableButton: false,
    }
  },

  watch: {
    'request.clientId': {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.getFarms()
        }
        this.farms = null
        this.request.farmId = ''
      },
    },

    'request.farmId': {
      handler(newVal, oldVal) {
        if (newVal !== '') {
          this.getAreas()
        }
        this.areas = []
        this.request.areas = []
      },
    },
  },

  methods: {
    async getHarvests() {
      try {
        this.harvests = await this.$store.dispatch('getHarvests')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getClients() {
      try {
        this.clients = await this.$store.dispatch('getClients', {
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getFarms() {
      try {
        this.farms = await this.$store.dispatch('getFarms', {
          clientId: this.request.clientId,
          status: 1,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getAreas() {
      try {
        const response = await this.$store.dispatch('getAreas', {
          clientId: this.request.clientId,
          farmId: this.request.farmId,
          status: 1,
        })

        response.forEach((area) => {
          this.areas.push({
            id: area.id,
            name: area.name,
            hectare: area.hectare,
            statusClass: area.statusClass,
            statusCode: area.statusCode,
            statusName: area.statusName,
            requestId: area.requestId,
            numberGrid: '',
            observation: '',
          })
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async getSamplers() {
      try {
        this.samplers = await this.$store.dispatch('getSamplers')
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'saveRequest',
            this.request,
          )
          this.disableButton = false
          this.$root.$children[0].$refs.notification.success(response.message)
          this.$router.push(PATHS.PRECISION_HOME)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    request: {
      harvestId: {
        required,
      },
      clientId: {
        required,
      },
      farmId: {
        required,
      },
      areas: {
        required,
      },
      samplers: {
        required,
      },
      contactPhone: {
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      collectionDate: {
        required,
      },
    },
  },

  mounted() {
    this.getHarvests()
    this.getClients()
    this.getSamplers()
  },
}
</script>
