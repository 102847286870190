<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          <router-link to="/precision/reports" title="Voltar">
            Relatórios
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Localização das Áreas
        </h5>
      </div>
      <loading-data v-if="loadData" />
      <div v-else class="card-body">
        <report-area-location :locations="locations" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import ReportAreaLocation from '@/components/precision/report/ReportAreaLocation.vue'

export default {
  name: 'PrecisionReportAreaLocation',

  components: {
    LoadingData,
    ReportAreaLocation,
  },

  data() {
    return {
      loadData: true,
      locations: null,
    }
  },

  methods: {
    async search() {
      try {
        this.locations = await this.$store.dispatch('getReportAreasLocation')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.search()
  },
}
</script>
