<template>
  <table class="table" width="100%" :id="dataTable.id">
    <thead>
      <tr>
        <th>Id</th>
        <th>Cliente</th>
        <th>Fazenda</th>
        <th>Safra</th>
        <th>Solicitante</th>
        <th>Status</th>
        <th>Criado por</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="request in requests" :key="request.id">
        <td>{{ request.id }}</td>
        <td>{{ request.clientName }}</td>
        <td>{{ request.farmName }}</td>
        <td>{{ request.harvestName }}</td>
        <td>{{ request.userName }}</td>
        <td>
          <span :class="`badge bg-${request.statusClass}`">
            {{ request.statusName }}
          </span>
        </td>
        <td>{{ createdUpdatedUser(request.createdBy, request.createdAt) }}</td>
        <td class="text-center">
          <router-link
            :to="`requests/${request.id}`"
            class="btn btn-outline-primary btn-icon rounded-pill"
            title="Detalhes"
          >
            <i class="far fa-file-alt" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-responsive'
import '@/utils/basicDataTableExtend'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'ListRequests',

  props: {
    requests: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListRequests',
        responsive: true,
        columnDefs: [
          {
            width: 50,
            targets: [0],
          },
          {
            orderable: false,
            width: 100,
            targets: [7],
          },
        ],
      },
      createdUpdatedUser,
    }
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        order: [[0, 'desc']],
        responsive: this.dataTable.responsive,
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
