<template>
  <div
    id="modalRegisterMaintenancePlanEquipment"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Registrar Manutenção</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>
                  Data da Manutenção:
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control"
                  :class="{
                    'is-invalid': $v.registerMaintenance.dateMaintenance.$error,
                  }"
                  placeholder="Data da Manutenção"
                  v-model="$v.registerMaintenance.dateMaintenance.$model"
                />
                <span
                  v-if="!$v.registerMaintenance.dateMaintenance.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>
                  Valor ({{ maintenancePlan.controlUnit }}):
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      $v.registerMaintenance.valueMaintenance.$error,
                  }"
                  min="0"
                  step="1"
                  placeholder="Valor"
                  v-model.number="
                    $v.registerMaintenance.valueMaintenance.$model
                  "
                />
                <span
                  v-if="!$v.registerMaintenance.valueMaintenance.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label>Tarefas Executadas:</label>
                <div
                  v-for="task in maintenancePlan.tasks"
                  :key="task.id"
                  class="row mb-2"
                >
                  <div class="col-12">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        :id="`task_${task.id}`"
                        class="custom-control-input"
                        :value="task.name"
                        v-model="registerMaintenance.tasks"
                      />
                      <label
                        :for="`task_${task.id}`"
                        class="custom-control-label"
                      >
                        {{ task.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Observações:</label>
                <textarea
                  class="form-control"
                  placeholder="Observações"
                  v-model="registerMaintenance.observation"
                  rows="4"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Cadastrar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Cadastrar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'RegisterMaintenancePlanEquipmentModal',

  props: {
    equipmentId: {
      type: String,
      required: true,
    },
    maintenancePlanId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      maintenancePlan: {},
      registerMaintenance: {
        maintenancePlanId: this.maintenancePlanId,
        dateMaintenance: '',
        valueMaintenance: '',
        observation: '',
        tasks: [],
      },
      disableButton: false,
    }
  },

  methods: {
    async getMaintenancePlan() {
      try {
        this.maintenancePlan = await this.$store.dispatch(
          'getMaintenancePlan',
          this.maintenancePlanId,
        )
        window.$('#modalRegisterMaintenancePlanEquipment').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalRegisterMaintenancePlanEquipment').modal('hide')
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'registerMaintenanceEquipment',
            {
              equipmentId: this.equipmentId,
              maintenance: this.registerMaintenance,
            },
          )
          this.disableButton = false
          window.$('#modalRegisterMaintenancePlanEquipment').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    registerMaintenance: {
      dateMaintenance: {
        required,
      },
      valueMaintenance: {
        required,
      },
    },
  },

  mounted() {
    this.getMaintenancePlan()
  },
}
</script>
