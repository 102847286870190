<template>
  <div
    id="modalHistoricMaintenancePlanEquipment"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-full">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Histórico de Manutenção</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <table class="table">
            <thead>
              <tr>
                <th>Data da Manutenção</th>
                <th>Realizada com</th>
                <th>Observações</th>
                <th>Cadastrada Por</th>
                <th>Tarefas Executadas</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="maintenance in maintenances" :key="maintenance.id">
                <td width="10%">{{ dateSqlToDateBR(maintenance.date) }}</td>
                <td width="10%">{{ maintenance.value }}</td>
                <td class="white-space-pre-line" width="50%">
                  {{ maintenance.observation }}
                </td>
                <td>
                  {{
                    createdUpdatedUser(
                      maintenance.createdBy,
                      maintenance.createdAt,
                    )
                  }}
                </td>
                <td>
                  <ul class="list-square">
                    <li v-for="task in maintenance.tasks" :key="task.id">
                      {{ task.nameTask }}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-link"
            data-dismiss="modal"
            title="Cancelar"
            @click="$emit('closeModal', false)"
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateSqlToDateBR } from '@/utils/date'
import { createdUpdatedUser } from '@/utils/strings'

export default {
  name: 'HistoricMaintenancePlanEquipmentModal',

  props: {
    equipmentId: {
      type: String,
      required: true,
    },
    maintenancePlanId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      maintenances: {},
      dateSqlToDateBR,
      createdUpdatedUser,
    }
  },

  methods: {
    async getHistoricMaintenanceEquipment() {
      try {
        this.maintenances = await this.$store.dispatch(
          'getHistoricMaintenanceEquipment',
          {
            equipmentId: this.equipmentId,
            maintenancePlanId: this.maintenancePlanId,
          },
        )
        window.$('#modalHistoricMaintenancePlanEquipment').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        window.$('#modalHistoricMaintenancePlanEquipment').modal('hide')
        this.$emit('closeModal', false)
      }
    },
  },

  mounted() {
    this.getHistoricMaintenanceEquipment()
  },
}
</script>

<style scoped>
.white-space-pre-line {
  white-space: pre-line !important;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
