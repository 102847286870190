var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataReport.byLaboratory.length > 0)?_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('report-buttons',{attrs:{"nameExcel":'Relatorio_Destino_das_Amostras_Resumo',"worksheetExcel":_vm.titleByLaboratory,"fieldsExcel":_vm.fieldsByLaboratoryExcel,"dataExcel":_vm.dataReport.byLaboratory,"divElementPrint":'printReportByLaboratory'}}),_c('div',{attrs:{"id":"printReportByLaboratory"}},[_c('report-header',{attrs:{"title":_vm.titleByLaboratory}}),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered dataTable table-report"},[_c('thead',[_c('tr',[_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnByLaboratory === 'laboratory' &&
                        _vm.dataReport.sortTypeByLaboratory === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnByLaboratory === 'laboratory' &&
                        _vm.dataReport.sortTypeByLaboratory === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnByLaboratory('laboratory')}}},[_vm._v(" Laboratório ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnByLaboratory === 'hectare' &&
                        _vm.dataReport.sortTypeByLaboratory === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnByLaboratory === 'hectare' &&
                        _vm.dataReport.sortTypeByLaboratory === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnByLaboratory('hectare')}}},[_vm._v(" Coletado ")])])]),_c('tbody',[_vm._l((_vm.dataReport.byLaboratory),function(report,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(report.laboratory))]),_c('td',[_vm._v(_vm._s(report.hectareToCurrencyBr))])])}),_c('tr',[_vm._m(0),_c('td',[_vm._v(_vm._s(_vm.dataReport.totals.byLaboratory))])])],2)])])],1)],1)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('report-buttons',{attrs:{"nameExcel":'Relatorio_Destino_das_Amostras_Detalhe',"worksheetExcel":_vm.titleDetails,"fieldsExcel":_vm.fieldsDetailsExcel,"dataExcel":_vm.dataReport.details,"divElementPrint":'printReportDetails'}}),_c('div',{attrs:{"id":"printReportDetails"}},[_c('report-header',{attrs:{"title":_vm.titleDetails}}),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered dataTable table-report"},[_c('thead',[_c('tr',[_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnDetails === 'laboratory' &&
                        _vm.dataReport.sortTypeDetails === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnDetails === 'laboratory' &&
                        _vm.dataReport.sortTypeDetails === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnDetails('laboratory')}}},[_vm._v(" Laboratório ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnDetails === 'client' &&
                        _vm.dataReport.sortTypeDetails === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnDetails === 'client' &&
                        _vm.dataReport.sortTypeDetails === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnDetails('client')}}},[_vm._v(" Cliente ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnDetails === 'farm' &&
                        _vm.dataReport.sortTypeDetails === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnDetails === 'farm' &&
                        _vm.dataReport.sortTypeDetails === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnDetails('farm')}}},[_vm._v(" Fazenda ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnDetails === 'area' &&
                        _vm.dataReport.sortTypeDetails === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnDetails === 'area' &&
                        _vm.dataReport.sortTypeDetails === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnDetails('area')}}},[_vm._v(" Talhão ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnDetails === 'hectare' &&
                        _vm.dataReport.sortTypeDetails === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnDetails === 'hectare' &&
                        _vm.dataReport.sortTypeDetails === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnDetails('hectare')}}},[_vm._v(" Hectare ")]),_c('th',{staticClass:"sorting",class:{
                      sorting_asc:
                        _vm.dataReport.sortColumnDetails === 'date' &&
                        _vm.dataReport.sortTypeDetails === 'asc',
                      sorting_desc:
                        _vm.dataReport.sortColumnDetails === 'date' &&
                        _vm.dataReport.sortTypeDetails === 'desc',
                    },on:{"click":function($event){return _vm.sortColumnDetails('date')}}},[_vm._v(" Data da Coleta ")])])]),_c('tbody',[_vm._l((_vm.dataReport.details),function(report,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(report.laboratory))]),_c('td',[_vm._v(_vm._s(report.client))]),_c('td',[_vm._v(_vm._s(report.farm))]),_c('td',[_vm._v(_vm._s(report.area))]),_c('td',[_vm._v(_vm._s(report.hectareToCurrencyBr))]),_c('td',[_vm._v(_vm._s(report.dateToCurrencyBr))])])}),_c('tr',[_vm._m(1),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v(_vm._s(_vm.dataReport.totals.details))]),_c('td',[_vm._v("-")])])],2)])])],1)],1)])]):_c('report-not-found')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Total")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Total")])])}]

export { render, staticRenderFns }