<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr class="text-center">
          <th class="w-25">
            <img
              src="@/assets/img/modules/agricultura-precisao.png"
              height="70"
              alt="Agricultura de Precisão"
            />
          </th>
          <th class="w-50">RELATÓRIO <br />{{ title.toUpperCase() }}</th>
          <th class="w-25">
            DATA DE GERAÇÃO <br /><small>{{ dateNow() }}</small>
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import { dateNow } from '@/utils/date'

export default {
  name: 'ReportHeader',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dateNow,
    }
  },
}
</script>
