<template>
  <div>
    <div v-if="dataReport.report.length > 0" class="card">
      <div class="card-body">
        <div>
          <report-buttons
            :nameExcel="'Relatorio_Status_dos_Talhoes'"
            :worksheetExcel="title"
            :fieldsExcel="fieldsExcel"
            :dataExcel="dataReport.report"
            :divElementPrint="'printReport'"
          />
          <div id="printReport">
            <report-header :title="title" />
            <div class="table-responsive">
              <table class="table table-bordered dataTable table-report">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'farmName' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'farmName' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('farmName')"
                    >
                      Fazenda
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'name' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'name' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('name')"
                    >
                      Talhão
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'areaHectare' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'areaHectare' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('areaHectare')"
                    >
                      Área (ha)
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'areaRequested' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'areaRequested' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('areaRequested')"
                    >
                      Área Solicitada (ha)
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'areaMeasure' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'areaMeasure' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('areaMeasure')"
                    >
                      Área Coletada (ha)
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'lastDate' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'lastDate' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('lastDate')"
                    >
                      Data da Coleta
                    </th>
                    <th
                      class="sorting"
                      :class="{
                        sorting_asc:
                          dataReport.sortColumn === 'status' &&
                          dataReport.sortType === 'asc',
                        sorting_desc:
                          dataReport.sortColumn === 'status' &&
                          dataReport.sortType === 'desc',
                      }"
                      @click="sortColumn('status')"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(report, index) in dataReport.report" :key="index">
                    <td>{{ report.farmName }}</td>
                    <td>{{ report.name }}</td>
                    <td>{{ report.areaHectareToCurrencyBr }}</td>
                    <td>{{ report.areaRequestedToCurrencyBr }}</td>
                    <td>{{ report.areaMeasureToCurrencyBr }}</td>
                    <td>{{ report.lastDateToCurrencyBr }}</td>
                    <td>{{ report.status !== null ? report.status : '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>-</td>
                    <td>{{ dataReport.totals.areaHectare }}</td>
                    <td>{{ dataReport.totals.areaRequested }}</td>
                    <td>
                      {{ dataReport.totals.areaMeasure }}
                      <span title="Área Coletada (ha) / Área (ha)">
                        ({{ dataReport.totals.areaMeasurePercent }}%)
                      </span>
                    </td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <report-not-found v-else />
  </div>
</template>

<script>
import sortArray from 'sort-array'
import ReportButtons from '@/components/precision/report/ReportButtons.vue'
import ReportHeader from '@/components/precision/report/ReportHeader.vue'
import ReportNotFound from '@/components/precision/report/ReportNotFound.vue'

export default {
  name: 'ReportStatusPlots',

  props: {
    dataReport: {
      type: Object,
      required: true,
    },
  },

  components: {
    ReportHeader,
    ReportButtons,
    ReportNotFound,
  },

  data() {
    return {
      title: 'Status dos Talhões',
      fieldsExcel: {
        'Fazenda': 'farmName',
        'Talhão': 'name',
        'Área (ha)': 'areaHectare',
        'Área Solicitada (ha)': 'areaRequested',
        'Área Coletada (ha)': 'areaMeasure',
        'Data da Coleta': 'lastDateToCurrencyBr',
        'Status': 'status',
      },
    }
  },

  methods: {
    async sortColumn(column) {
      this.dataReport.sortColumn = column
      this.dataReport.sortType =
        this.dataReport.sortType === '' || this.dataReport.sortType === 'desc'
          ? 'asc'
          : 'desc'

      sortArray(this.dataReport.report, {
        by: this.dataReport.sortColumn,
        order: this.dataReport.sortType,
      })
    },
  },
}
</script>
