<template>
  <div id="viewDiv"></div>
</template>

<script>
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import esriConfig from '@arcgis/core/config'
import Graphic from '@arcgis/core/Graphic'
import FeatureLayer from '@arcgis/core/layers/FeatureLayer'
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery'

esriConfig.apiKey = process.env.VUE_APP_ARCGIS_API_KEY

export default {
  name: 'ReportFarmLocation',

  props: {
    locations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      graphicsArray: [],
      fields: [
        {
          name: 'id',
          alias: 'id',
          type: 'oid',
        },
        {
          name: 'farmName',
          alias: 'Fazenda',
          type: 'string',
        },
        {
          name: 'clientName',
          alias: 'Cliente',
          type: 'string',
        },
        {
          name: 'clientEmail',
          alias: 'E-mail',
          type: 'string',
        },
        {
          name: 'clientPhone',
          alias: 'Telefone',
          type: 'string',
        },
        {
          name: 'area',
          alias: 'Área',
          type: 'string',
        },
        {
          name: 'latitude',
          alias: 'Latitude',
          type: 'string',
        },
        {
          name: 'longitude',
          alias: 'Longitude',
          type: 'string',
        },
      ],
      popupTemplate: {
        title: '{farmName}',
        content: `
          <p><b>Cliente:</b> {clientName}</b></p>
          <p><b>E-mail:</b> {clientEmail}</b></p>
          <p><b>Telefone:</b> {clientPhone}</b></p>
          <p><b>Área:</b> {area}ha</b></p>
          <p><b>Latitude:</b> {latitude}</b></p>
          <p><b>Longitude:</b> {longitude}</b></p>
        `,
      },
    }
  },

  methods: {
    createMap() {
      const map = new Map({
        basemap: 'arcgis-community',
      })

      const farmLayer = new FeatureLayer({
        title: 'Localização das Fazendas',
        fields: this.fields,
        objectIdField: 'id',
        geometryType: 'point',
        spatialReference: { wkid: 4326 },
        source: [], // adding an empty feature collection
        popupTemplate: this.popupTemplate,
      })
      map.add(farmLayer)

      const itens = this.formatToGraphicObject()

      farmLayer
        .applyEdits(itens)
        .then((results) => {
          if (results.addFeatureResults.length > 0) {
            const objectIdsa = []
            results.addFeatureResults.forEach((item) => {
              objectIdsa.push(item.objectId)
            })
            farmLayer.queryFeatures({ objectIds: objectIdsa }).then(() => {})
          }
        })
        .catch((error) => {
          console.error()
        })

      const mapView = new MapView({
        map,
        center: [-51.9253, -14.235], // Longitude, latitude
        zoom: 5.6,
        container: 'viewDiv',
      })

      const basemapGallery = new BasemapGallery({
        view: mapView,
        source: {
          query: {
            title: '"World Basemaps for Developers" AND owner:esri',
          },
        },
      })

      mapView.ui.add(basemapGallery, 'top-right') // Add to the view
    },

    formatToGraphicObject() {
      const graphics = []

      this.locations.forEach((element) => {
        const formatedItem = {
          geometry: {
            type: 'point',
            latitude: Number(element.latitude),
            longitude: Number(element.longitude),
          },
          attributes: element,
        }

        const newGraphic = new Graphic(formatedItem)
        graphics.push(newGraphic)
        this.graphicsArray.push(newGraphic)
      }, this)

      const addEdits = {
        addFeatures: graphics,
      }

      return addEdits
    },
  },

  mounted() {
    this.createMap()
  },
}
</script>

<style scoped>
@import url('https://js.arcgis.com/4.21/esri/themes/light/main.css');
#viewDiv {
  height: calc(100vh - 220px);
  width: 100%;
}
.esri-ui-top-right.esri-ui-corner {
  max-width: 270px;
}
.esri-view-width-greater-than-large
  .esri-ui-corner
  .esri-basemap-gallery.esri-component {
  max-width: 270px;
}
</style>
