<template>
  <div class="content">
    <div class="card">
      <div class="card-header bg-transparent">
        <h5 class="card-title">
          <router-link to="/precision/home" title="Voltar">
            Módulo de Precisão
          </router-link>
          <i class="fas fa-angle-right ml-2" />
          Equipamentos
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="equipments/list" title="Acessar">
              <div class="card h-100" title="Cadastro/Listagem de Equipamentos">
                <div class="card-body text-center">
                  <i
                    class="far fa-list-alt fa-2x text-primary border-primary border-3 rounded-pill p-3 my-1"
                  >
                  </i>
                  <h5 class="card-title text-primary mt-2 mb-0">
                    Cadastro/Listagem
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-3 col-sm-6 mb-3">
            <router-link to="equipments/maintenance-plans" title="Acessar">
              <div class="card h-100" title="Planos de Manutenção">
                <div class="card-body text-center">
                  <i
                    class="fas fa-wrench fa-2x text-purple border-purple border-3 rounded-pill p-3 my-1"
                  />
                  <h5 class="card-title text-purple mt-2 mb-0">
                    Planos de Manutenção
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrecisionEquipmentsHome',
}
</script>
