<template>
  <table class="table" :id="dataTable.id">
    <thead>
      <tr>
        <th>Número do Quadriciclo</th>
        <th>Ano</th>
        <th>Chassi</th>
        <th>Cor</th>
        <th class="text-center">Status</th>
        <th class="text-center">Existe Manutenção Atrasada</th>
        <th class="text-center">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="equipment in equipments" :key="equipment.id">
        <td>{{ equipment.name }}</td>
        <td>{{ equipment.year }}</td>
        <td>{{ equipment.chassi }}</td>
        <td>{{ equipment.color }}</td>
        <td class="text-center" v-html="statusTranslate(equipment.status)" />
        <td class="text-center">
          <span
            class="badge"
            :class="{
              'badge-danger': equipment.expiredMaintenance,
              'badge-primary': !equipment.expiredMaintenance,
            }"
          >
            {{ equipment.expiredMaintenance ? 'Sim' : 'Não' }}
          </span>
        </td>
        <td class="text-center">
          <button
            v-if="getModuleUserByCode('2.06.003')"
            type="button"
            class="btn btn-outline-warning btn-icon rounded-pill"
            title="Editar"
            @click="$emit('editEquipment', equipment.id)"
          >
            <i class="icon-pencil" />
          </button>
          <router-link
            :to="`list/${equipment.id}`"
            class="btn btn-outline-primary btn-icon rounded-pill ml-2"
            title="Detalhes"
          >
            <i class="far fa-file-alt" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import 'datatables.net-dt/js/dataTables.dataTables'
import '@/utils/basicDataTableExtend'
import { mapGetters } from 'vuex'
import { statusTranslate } from '@/utils/strings'

export default {
  name: 'ListEquipments',

  props: {
    equipments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListEquipments',
        columnDefs: [
          {
            orderable: false,
            width: 130,
            targets: [6],
          },
        ],
      },
      statusTranslate,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        columnDefs: this.dataTable.columnDefs,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>
